import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import { BgImage } from "../assets/images";
import { EquipPic } from "../assets/images";
function HistoryPage(props) {
  return (
    <>
      <MenuComponent></MenuComponent>
      <section className="eq_container clearfix">
        <div className="eq_top_img" data-parallax='{"x": -180}'>
          <img src={EquipPic} className="img_equip_pic" />
        </div>
        <div className="eq_top_sect clearfix">
          <div className="eq_content clearfix">
            <div className="eq_sentence">
              <div className="eq_sentence_title">
                <h2 className="txt_h2txt">Anomaly Detection</h2>
                <br />
                <h1 className="txt_equip_title">History</h1>
              </div>
            </div>
          </div>
          <div className="eq_top_bg short clearfix" />
        </div>
      </section>
      {/* Select Categories */}
      {/* Data */}
      <section className="data_container eq_b32 clearfix">
        {/* <div className="data_subject_title clearfix">
          <div className="eq_sentence">
            <h2 className="txt_h3txt_head">previous progress list</h2>
          </div>
        </div> */}
        <iframe className="data_content h_mockup clearfix" title="ML_Dashboard" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=199b2894-7b04-4483-a284-7002b6c81a15&autoAuth=true&ctid=d39b3018-688f-4d0d-9fce-8c3cb1169c21" frameborder="0" allowFullScreen="true"></iframe>
        {/* <div className="data_content h_mockup clearfix" /> */}
      </section>
      {/* Data */}
      {/* BG */}
      <div
        className="ml_sect_bg ml_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}
      />
      {/* BG */}
      {/*Modal Case Issue */}
      <div
        className="modal fade"
        id="case_issue"
        data-keyboard="false"
        data-backdrop="false">
        {/*?php include("case_issue.php"); ?*/}
      </div>
      {/*Modal Case Issue */}
      <FooterComponent></FooterComponent>
    </>
  );
}
export default HistoryPage;
