import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { updateAccount, updateAuth } from "./redux/action/azure.update";
import { useMsal } from "@azure/msal-react";

import Home from "./pages/HomePage";
import HomeNew from "./pages/HomePageNew";
import EquipmentPage from "./pages/EquipmentPage";
import EquipmentPageNew from "./pages/EquipmentPageNew";
import SigninPage from "./pages/SigninPage";
import { useIsAuthenticated } from "@azure/msal-react";

import { baseRoute, loginRequest } from "./configs/constant.key";
import React, { useState, useRef, useEffect } from "react";
import IssueSummary from "./pages/IssueSummary";
import HistoryPage from "./pages/HistoryPage";

import "./App.css";
import EquipmentDetail from "./pages/EquipmentDetail";
import NotifyMessage from "./components/notification";
import { ServiceGetUserEditingRoles } from "./services/machine.learning.service";

function App(props) {
  let tmpProps = props;
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const name = accounts[0] && accounts[0].name;
  const isAuthenticated = useIsAuthenticated();
  const RequestAccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
        props.updateAccount({ ...response });
        props.GetUserEditingRoles();
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken);
          props.updateAccount({ ...response });
        });
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      RequestAccessToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (tmpProps.notify.isShow === true && tmpProps.notify.status !== 401) {
      NotifyMessage(tmpProps.notify);
    }
    return () => { };
  }, [tmpProps.notify]);

  return (
    <div className="App">
      {isAuthenticated && accessToken ? (
        <Routes>
          <Route path={`/${baseRoute}`} element={<HomeNew {...props} />} />
          <Route
            path={`/${baseRoute}/equipment/:plantCode`}
            element={<EquipmentPageNew {...props} />}
          />
          <Route
            path={`/${baseRoute}/history`}
            element={<HistoryPage {...props} />}
          />
          <Route
            path={`/${baseRoute}/issue_summary`}
            element={<IssueSummary {...props} />}
          />
          <Route
            path={`/${baseRoute}/equipment_detail/:plantCode`}
            element={<EquipmentDetail {...props} />}
          />
        </Routes>
      ) : (
        <SigninPage {...props} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAccount: (account) => {
    dispatch(updateAccount(account));
  },
  updateAuth: (account) => {
    dispatch(updateAuth(account));
  },
  GetUserEditingRoles: (props) => {
    dispatch(ServiceGetUserEditingRoles(props));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
