const initialState = {
  defaultPlant: null,
  allPlant: null,
  equipmentByPlant: null,
  logUpdateIssue: null,

};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_DEFAULF_PLANT_BY_USER_ID":
      return Object.assign({}, state, {
        defaultPlant: action.payload.data,
      });
    case "CLEAR_DEFAULF_PLANT_BY_USER_ID":
      return Object.assign({}, state, {
        defaultPlant: null,
      });
    case "GET_ALL_PLANT_BY_USER_ID":
      return Object.assign({}, state, {
        allPlant: action.payload.data,
      });
    case "CLEAR_ALL_PLANT_BY_USER_ID":
      return Object.assign({}, state, {
        allPlant: null,
      });
    case "GET_EQUIPMENT_BY_PLANT":
      return Object.assign({}, state, {
        equipmentByPlant: action.payload.data,
      });
    case "CLEAR_EQUIPMENT_BY_PLANT":
      return Object.assign({}, state, {
        equipmentByPlant: null,
      });
    case "GET_LOG_UPDATE_ISSUE":
      return Object.assign({}, state, {
        logUpdateIssue: action.payload.data,
      });
    case "CLEAR_LOG_UPDATE_ISSUE":
      return Object.assign({}, state, {
        logUpdateIssue: null,
      });
    
    default:
      return state;
  }
};
