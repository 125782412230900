export const updateAccount = (payload) => ({
    type: "UPDATE_ACCOUNT",
    payload,
});

export const updateError = (payload) => ({
    type: "UPDATE_ERROR",
    payload,
});

export const updateProfile = (payload) => ({
    type: "UPDATE_PROFILE",
    payload,
});

export const updateToken = (payload) => ({
    type: "UPDATE_TOKEN",
    payload,
});

export const updateAuth = (payload) => ({
    type: "UPDATE_AUTH",
    payload,
});

export const updateUI = (payload) => ({
    type: "UPDATE_UI",
    payload,
});

export const updatePlantSelected = (payload) => ({
    type: "UPDATE_SELECT_PLANT",
    payload,
});

export const updateApplicationLoading = (payload) => ({
    type: "UPDATE_APPLICATION_LOADING",
    payload,
});
