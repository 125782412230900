import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import moment from "moment";

import { Spin } from "antd";

import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import AnomalyChart from "../components/AnomalyChartGraph";


import {
  useParams,
  useLocation,
  Link,
  NavLink,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ServiceGetSensor,
  ServiceGetLoadType,
  ServiceGetHealthScore,
  ServiceGetSensorImg,
} from "../services/machine.learning.service.js";

import { ActionStoreSensorImg } from "../redux/action/action.machine.learning";
import HealthScoreGauge from "../components/HealthScoreGauge";


import {
  EquipPic,
  EquipIco,
  HalfChart2,
  SensorDetect,
  BgImage,
} from "../assets/images";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function EquipmentDetail(props) {
  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DD HH:mm:ss";

  const dispatch = useDispatch();
  const equipment = useParams();
  const [searchParams] = useSearchParams();
  const equipId = searchParams.get("equipId");
  const [date, setDate] = useState(searchParams.get("date"));
  const [datesensor, setDatesensor] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const reduxSensor = useSelector((state) => state.machineLearning.sensor);
  const reduxLoadType = useSelector((state) => state.machineLearning.loadType);
  const reduxHealthScore = useSelector(
    (state) => state.machineLearning.healthScore
  );
  const reduxSensorImg = useSelector(
    (state) => state.machineLearning.sensorImg
  );
  const [equipmentDetail, setEquipmentDetail] = useState(null);
  const [sensor, setSensor] = useState(null);
  const [loadType, setLoadType] = useState(null);
  const [sensorImg, setSensorImg] = useState(null);

  const [selectedSensors, setSelectedSensors] = useState([0, 1, 2]);
  const [selectedSensorsData, setSelectedSensorsData] = useState(null);
  const [isLoadingAnomaly, setIsLoadingAnomaly] = useState(true);
  let [anomaly, setAnomaly] = useState(null);
  const [dis, setdis] = useState(null);

  //! GET REDUX HEALTHSCORE

  useEffect(() => {
    dispatch(
      ServiceGetHealthScore(
        JSON.stringify({ plantCode: equipment.plantCode, date: date })
      )
    );
  }, [date]);

  //! GET REDUX SENSOR
  useEffect(() => {
    dispatch(
      ServiceGetSensor(
        JSON.stringify({
          plantCode: equipment.plantCode,
          equiP_ID: equipId,
          date: date,
        })
      )
    );
  }, [date]);

  //! GET REDUX LOADTYPE
  useEffect(() => {
    dispatch(
      ServiceGetLoadType(
        JSON.stringify({
          plantCode: equipment.plantCode,
          equiP_ID: equipId,
          date: date,
        })
      )
    );
  }, [date]);

  //! GET REDUX SENSOR IMG
  // useEffect(() => {
  //   const fetchSensorImages = async () => {
  //     if (selectedSensorsData !== null) {
  //       setIsLoading(true);
  //       if (selectedSensorsData.length > 0) {
  //         try {
  //           const sensorImages = await Promise.all(
  //             selectedSensorsData.map((sensor) =>
  //               dispatch(
  //                 ServiceGetSensorImg(
  //                   JSON.stringify({ paraM_ID: sensor, dataDate: date })
  //                 )
  //               )
  //             )
  //           );

  //           dispatch(ActionStoreSensorImg(sensorImages));
  //           // Do something with the sensorImages array, such as updating state
  //         } catch (error) {
  //           // Handle errors if any of the requests fail
  //           console.error("Error fetching sensor images:", error);
  //         }
  //       } else {
  //         dispatch(ActionStoreSensorImg([]));
  //       }
  //     } else {
  //       dispatch(ActionStoreSensorImg([]));
  //     }
  //   };

  //   fetchSensorImages();
  // }, [date, selectedSensorsData]);
  //! SET THE DATA

  useEffect(() => {
    if (reduxHealthScore) {
      setEquipmentDetail(
        reduxHealthScore.filter((item) => item.equipId == equipId)
      );
    }
  }, [reduxHealthScore]);

  useEffect(() => {
    setSensor(reduxSensor);
    dataGraph(reduxSensor)
    itemDistinct(reduxSensor)
  }, [reduxSensor]);

  useEffect(() => {
    setLoadType(reduxLoadType);
  }, [reduxLoadType]);
  useEffect(() => {
    setIsLoading(false);
  }, [reduxSensorImg]);

  useEffect(() => {
    if (dis) {
      setSelectedSensorsData(
        selectedSensors.map((item) => {
          return dis[item].paramId.paramId;
        })
      );
    }
    // console.log("karun",selectedSensorsData)
    // console.log("karun2",reduxSensor)
    // console.log("karun3",dis)
  }, [dis, selectedSensors]);


  const dataGraph = async (dataSensor) => {
       setAnomaly(dataSensor)

    if (dataSensor == null) {
      // console.log('setNUll')
    }
    else {
      // dataSensor.forEach(async (el, index) => {
      //   await setDatesensor(el.paramDatetime);
      //   console.log('setDatesensor', dateGraph)

      // });
      // setDatesensor(dataSensor.paramDatetime.format(dataFormatApi));
      // console.log('dataSensordate',dataSensor.paramDatetime.format(dataFormatApi))

      setIsLoadingAnomaly(false)
    }
  }

  const handleDateChange = async (date) => {
    setDate(date.format(dataFormatApi));
    setIsLoading(true);

    setOpen(false);
  };


  const handleItemClick = (itemIndex, paramid) => {
    // console.log('item',itemIndex, paramid)
    if (selectedSensors.includes(itemIndex)) {
      setSelectedSensors(
        selectedSensors.filter((index) => index !== itemIndex)
      );
    } else {
      setSelectedSensors([...selectedSensors, itemIndex].sort((a, b) => a - b));
    }
    // console.log("ky",selectedSensors)
  };

  function base64ToImage(base64String) {
    const image = new Image();
    image.src = "data:image/png;base64," + base64String;
    return image;
  }

  function getPlantCode(paramId, dataArray) {
    // console.log("$$$$$$", dataArray);
    // setAnomaly(dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].paramId === paramId) {
        // return dataArray[i].plantCode;
        return `${dataArray[i].paramDescription} : ${dataArray[i].paramCode}(${dataArray[i].paramUnit})`
      }
    }

    // Return null if no matching paramId was found
    return null;
  }

  const itemDistinct = async(item) => {
      // const dis = item.find(x => x.paramDescription) 
      const result = Array.from(new Set(item.map(s => s.paramId)))
        .map(paramId => {  
          return {
                   paramId: item.find(s => s.paramId === paramId),
          }
        })
    //  console.log('result',result)
     setdis(result)
     return result
  }

  function replaceCharInString(string) {
    const search = '_';
    const searchRegExp = new RegExp(search, 'g');
    const replaceWith = ' ';

    const result = string.replace(searchRegExp, replaceWith)

    return result;
  }


  return (
    <>
      <MenuComponent></MenuComponent>
      <section className="eq_container clearfix">
        <div className="eq_back clearfix">
          <NavLink to="/" className="eq_back_link">
            Home
          </NavLink>
          <NavLink
            to={`/equipment/${equipment.plantCode}`}
            className="eq_breadcum eq_back_link">
            Equipment Status
          </NavLink>
          <span className="eq_breadcum eq_breadcum_tar">Anomaly Detection</span>
        </div>
        <div className="eq_top_img" data-parallax='{"x": -180}'>
          <img src={EquipPic} className="img_equip_pic" />
        </div>
        <div className="eq_top_sect clearfix">
          <div className="eq_content clearfix">
            <div className="eq_sentence">
              <div className="eq_sentence_title">
                <h2 className="txt_h2txt">issue summary</h2>
                <br />
                <h1 className="txt_equip_title">Anomaly Detection</h1>
              </div>
              <div className="eq_sentence_cont">
                Artificial intelligence automatically detects
                <br />
                and prioritizes problems.
              </div>
            </div>
          </div>
          <div className="eq_top_bg short clearfix" />
        </div>
      </section>
      {/* Select Categories */}
      {/* Data */}
      <section className="data_container eq_b32 clearfix">
        <div className="ad_subject_title clearfix">
          <div className="eq_sentence">
            <h2 className="txt_h3txt_head">Detail Of System</h2>
          </div>
        </div>
        <div className="eq_details_container clearfix">
          <div className="ad_container_sect clearfix">
            {/* Details */}
            <div className="ad_container_l clearfix">
              <div className="ad_hc_tools clearfix">
                <div className="ad_hc_title">Health Score</div>
                <div className="ad_hc_chart">
                  {equipmentDetail ? (
                    <HealthScoreGauge
                      healthScore={equipmentDetail[0]}
                      textColor={"rgb(255,255,255)"}></HealthScoreGauge>
                  ) : (
                    <></>
                  )}

                  {/* <img src={HalfChart2} className="img_adhs_chart" /> */}
                </div>
              </div>
              
              <div className="ad_hc_tools clearfix">
                <div className="ad_hc_title alig_cen">Sensors Detection</div>
                <div className="ad_hc_navlist clearfix">
                  {dis ? (
                    dis.map((item, index) => (
                    
                      <div
                     className={
                          selectedSensors.includes(index)
                            ? `ad_sens_btn ad_sens_btn_nav selected`
                            : `ad_sens_btn ad_sens_btn_nav`
                        }
                        onClick={() => handleItemClick(index,item.paramId.paramId)}>
                         
                        <span className="ad_sens_num">{item.paramId.paramScore}</span>
                        <br />
                        <span className="ad_sens_cont">
                          { item.paramId.paramDescription }
                        </span> 
                      </div>
                    ))
                  ) : (
                    
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="ad_container_r clearfix">
              <div className="ad_hc_header clearfix">
                <div className="ad_hc_header_title">
                  <span className="txt_h4txt_plant">Plant</span>
                  <br />
                  <span className="txt_equip_plant">
                    {equipmentDetail ? equipmentDetail[0].plantCode : ""}
                  </span>
                </div>
                <div className="ad_hc_header_equip">
                  <span className="txt_h4txt_plant">Equipment</span>
                  <br />
                  <span className="txt_equip_acc">
                    {equipmentDetail ? replaceCharInString(equipmentDetail[0].equipmentName) : ""}
                    {/* {equipmentDetail[0].paramDescription} : {equipmentDetail[0].paramCode} */}
                  </span>
                </div>
                <div className="ad_hc_header_equip ac_sensors">
                  <span className="txt_h4txt_plant">Sensors</span>
                  <br />
                  <span className="txt_equip_acc">
                    {" "}
                    {equipmentDetail ? equipmentDetail[0].totalSensor : ""}
                  </span>
                </div>
                <span>
                  <img src={EquipIco} className="ad_hc_equip_img" />
                </span>
              </div>
            </div>
            <div className="ad_calendar_r clearfix">
              <form>
                <div className="cate_date_opt_txt">
                  Select Date :{" "}
                  <span className="co_wh">
                    <div className="graph_equip_search">
                      <DatePicker
                        className="graph_equip_date"
                        format={dateFormat}
                        onChange={handleDateChange}
                        onClick={() => setOpen(!open)}
                        defaultValue={moment().subtract(1, "day")}
                        value={
                          date
                            ? moment(date, dateFormat)
                            : moment("", dateFormat)
                        }
                        open={open}
                      />
                    </div>
                  </span>
                  <span>
                    <i
                      className="icon-calendar1 calend_ico"
                      onClick={() => setOpen(!open)}
                    />
                  </span>
                </div>
              </form>
            </div>
            <div className="ad_loadtype clearfix">
              <div className="ad_loadtype_head clearfix">
                <span>LOAD TYPE</span>
              </div>
              {loadType ? (
                loadType.map((item, index) => (
                  <div className="ad_loadtype_box clearfix">
                    <span className="ad_loadtype_txt txt_l txt_h5lt_num">
                      {item.loadtype}
                    </span>
                    <span className="ad_loadtype_txt txt_h5lt_result">
                      {item.weight}
                      <span className="txt_h5lt_percent">%</span>
                    </span>
                    <span className="ad_loadtype_txt txt_h5lt_mw">
                      {item.minValue} - {item.maxValue} MW
                    </span>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className="ad_sens_chart_sum clearfix">
              {/* Chart Summary */}
              {selectedSensorsData?.length > 0 && sensor?.length > 0 ? (
                selectedSensorsData.map((item, index) => {

                  return (
                    <div className="ad_sens_chart_sect clearfix">
                      <div className="data_content_title clearfix">
                        <div className="ad_sens_chart_title">
                          <span className="txt_h3txt_chart_title">
                            {getPlantCode(item, sensor)}
                          </span>
                        </div>
                        <div className="ad_sens_chart_num">
                          <span className="cate_detect_opt_txt">
                            Detection
                          </span>
                          <span className="co_warn_sta">
                            {sensor != null &&
                              sensor != undefined &&
                              sensor[item] ? (
                              <>{sensor[item].score}</>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </div>
                      {/* {
                        JSON.stringify(anomaly.filter(e => (e.paramId === item.data["paraM_ID"])).map((dataG) =>{
                              return dataG.dt
                        }))
                      } */}
                      {
                        <div className="pla_s_charts clearfix">
                          {sensor?.length > 0 ? (
                            isLoadingAnomaly ? null : (
                              <AnomalyChart anomalyData={anomaly.filter(e => (e.paramId === item)).sort((a, b) => a.date_time - b.date_time) } date={datesensor} />
                            )
                          ) : isLoadingAnomaly ? null : (
                            <h1>NO DATA</h1>
                          )}
                          <Spin tip="" size="large" spinning={isLoadingAnomaly}></Spin>
                        </div>
                        // (() => {
                        //   let elements = [];
                        //   for (let element in item.data) {
                        //     if (element != "paraM_ID") {
                        //       elements.push(item.data[element]);
                        //     }
                        //   }
                        //   return elements;
                        // })().map((img, index) => (
                        //   base64ToImage(img).src !=="data:image/png;base64,null"? (
                        //    <div className="ad_sens_chart_col clearfix">
                        //     <div className="ad_sens_chart_result">
                        //       <span className="txt_h5ad_count">
                        //         {index + 1}
                        //       </span>
                        //       <div className="ad_sens_chart_img">
                        //         {/* <img
                        //           src={base64ToImage(img).src}
                        //           className="chart_cap_img"
                        //         /> */}
                        //         <ModalImage
                        //         small={base64ToImage(img).src}
                        //         large={base64ToImage(img).src}
                        //         alt={base64ToImage(img).src}
                        //       />
                        //       </div>
                        //     </div>
                        //   </div>
                        //   ):(<></>)


                        // ))
                      }
                    </div>
                  );
                })
              ) : (
                <>NO DATA Graph</>
              )}
              <Spin tip="" size="large" spinning={isLoading}></Spin>

              {/* Chart Summary */}
            </div>
          </div>
        </div>
      </section>
      {/* Data */}
      {/* BG */}
      <div
        className="ml_sect_bg ml_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}
      />
      {/* BG */}
      {/*Modal Case Issue */}
      <div
        className="modal fade"
        id="case_issue"
        data-keyboard="false"
        data-backdrop="false">
        {/*?php include("case_issue.php"); ?*/}
      </div>
      {/*Modal Case Issue */}
      <FooterComponent></FooterComponent>
    </>
  );
}
export default EquipmentDetail;
