const initialState = {
  authority: null,
  account: null,
  error: null,
  idToken: null,
  accessToken: null,
  isAuthenticated: false,
  isAccessViewPage: false,
  imageProfile: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_ACCOUNT":
      return Object.assign({}, state, {
        account: action.payload,
        idToken: action.payload.idTokenClaims,
        accessToken: action.payload.accessToken,
        isAuthenticated: true,
      });
    case "UPDATE_AUTH":
      return Object.assign({}, state, {
        authority: action.payload.authority,
        account: action.payload.account,
        idToken: action.payload.idTokenClaims,
        accessToken: action.payload.accessToken,
        isAuthenticated: true,
        token: action.payload.idToken,
      });
    case "UPDATE_ERROR":
      return Object.assign({}, state, {
        error: action.payload,
        isAuthenticated: false,
      });
    case "UPDATE_TOKEN":
      return Object.assign({}, state, {
        idToken: action.payload.idTokenClaims,
        accessToken: action.payload.accessToken,
      });

    case "UPDATE_APPLICATION_LOADING":
      return Object.assign({}, state, {
        isApplicationLoading: action.payload,
      });
    case "CLEAR_SET_PERMISSION_ACCESS_VIEW_PAGE":
      return Object.assign({}, state, {
        isAccessViewPage: true,
      });
    case "SET_PERMISSION_ACCESS_VIEW_PAGE":
      //console.log('5555555555',action.payload.data)
      return Object.assign({}, state, {
        isAccessViewPage: action.payload.data,
      });
    case "UPDATE_PROFILE":
      return Object.assign({}, state, {
        imageProfile: action.payload,
      });
    default:
      return state;
  }
};
