const initialState = {
  notifi: null,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_USER_NOTIFICATION":
      return Object.assign({}, state, {
        notifi: action.payload.data,
      });
    case "CLEAR_USER_NOTIFICATION":
      return Object.assign({}, state, {
        notifi: null,
      });

    default:
      return state;
  }
};
