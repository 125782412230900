import React, { useState, useEffect, Fragment, useCallback } from "react";
import { DatePicker, Spin, Skeleton, Tooltip, Table, Select, Modal, Dropdown, Space, List } from "antd";
import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import AnomalyChart from "../components/AnomalyChart";
import AnomalyChartGraph from "../components/AnomalyChartGraph";
import AnomalyChartGraphSum from "../components/AnomalyChartGraphSum";
import BarChart from "../components/Graph";
import PlantStatus from "../components/PlantStatus";
import moment from "moment";
import CaseIssue from "../components/CaseIssue";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Collapse, Button, message } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SearchOutlined,
  RedoOutlined,
  ReloadOutlined,
  DownOutlined
} from '@ant-design/icons';
import {
  BgImage, IconEquipment, EquipPic,
  MainPic, DateIco, TimeIco,
  OffIco, WarningIco, AlertIco,
  RuntimeIco, CaseBtn, HalfChart, SearchIco,
  AlertInProgress, //alert yellow
  AlertClosed, // alert green
  AlertOff, // alert grey
  AlertOpen, // alert open
  RuntimeInProgressNormal, //runtime yellow
  RuntimeClosed, //runtime green
  RuntimeOpen, //runtime red
  RuntimeOff, // runtime grey
  casestatusopen, casestatusclose, New, New2, Assigned,
  Acknowledge, Pending, Clear,
  EquipIco
} from "../assets/images";
import { useParams, useLocation, Link, NavLink } from "react-router-dom";

import {
  ServiceGetHealthScore,
  ServiceGetAnomaly,
  ServiceGetEquipmentOverviewByPlantEnhance,
  ServiceGetPlantStatus,
  ServiceGetDetail,
  ServiceEnhGetIssueSummary,
  ServiceGetLogUpdateIssueLast,
  ServiceGetSensor,
  ServiceGetSensorSum,
  ServiceGetRanking,
  ServiceGetIssure,
  ServiceGetSensorV2,
  ServiceGetRumtime,
  ServiceGetAlert,
  ServiceEnhGetAllEquip,
  ServiceGetIssurebyEquipID,
  ServiceEnhGetSensorByparam,
  ServiceGetDateRank,
  ServiceGetDate
} from "../services/machine.learning.service";
import {
  ActionClearAnomaly,
  ActionClearPlantStatus,
  ActionClearDetail,
  ActionGetRanking,
  ActionClearByparam,
  ActionClearSensorSum,
  ActionClearRanking,
  ActionClearDateRank,
} from "../redux/action/action.machine.learning";

// import "../assets/css/custom-antdesign.css";
import "antd/dist/antd.min.css";

import "../assets/css/equipmentNew.css";
import "../assets/css/main.css";
import "../assets/css/style.css";
import HealthScoreGauge from "../components/HealthScoreGauge";
const { Panel } = Collapse;
const getDataAlert = (value) => {
  if (value === -1) {
    return { image: AlertOff, name: "Off" };
  }
  if (value === 0) {
    return { image: AlertClosed, name: "Normal" };
  }

  if (value === 1) {
    return { image: AlertInProgress, name: "Warning" };
  }
  if (value === 2) {
    return { image: AlertOpen, name: "Alert" };
  }
};

const getDataPriorty = (value) => {
  if (value === null) {
    return { image: AlertOff, name: "Unassigned" };
  }
  if (value === 0) {
    return { image: AlertClosed, name: "Normal" };
  }

  if (value === 1) {
    return { image: AlertInProgress, name: "Pending" };
  }
  if (value === 2) {
    return { image: AlertOpen, name: "High" };
  }
};

const getDataRuntime = (value) => {
  if (value === null) {
    return { image: RuntimeOff, name: "Off" };
  }
  if (value === "N") {
    return { image: RuntimeClosed, name: "Normal" };
  }

  if (value === "W") {
    return { image: RuntimeInProgressNormal, name: "Warning" };
  }
  if (value === "A") {
    return { image: RuntimeOpen, name: "Alert" };
  }
};
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  return formattedDate;
}


const styleTable = {
  text: {
    color: "#fff",
  },
  hText: {
    color: "#004370",
    fontWeight: "bold",
  },
};

const sortDataByEquipmentName = (data) => {
  return data.sort((a, b) => a.equipmentName.localeCompare(b.equipmentName));
};
const sortDataByScoreOutliner = (data) => {
  return data.sort((a, b) => b.scoreOutliner - a.scoreOutliner);
};

export const showGraph = (selectedSensorsData, setSelectedSensorsData, item) => {
  // console.log("selectedSensorsData", selectedSensorsData);
  // console.log("setSelectedSensorsData", setSelectedSensorsData);
  // console.log("item", item.key);

  // if (selectedSensorsData.includes(item.key)) {
  //   setSelectedSensorsData(selectedSensorsData.filter(i => i !== item.key));
  // } else {
  //   setSelectedSensorsData([...selectedSensorsData, item.key]);
  //   console.log("selectedSensorsDataIF", item.key);
  // }
};


const EquipmentNew = (props) => {

  const [isLoadingAnomaly, setIsLoadingAnomaly] = useState(true);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingPlantStatus, setIsLoadingPlantStatus] = useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);


  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
  let today = new Date();
  today.setDate(today.getDate() - 1)
  const equipment = useParams();

  const [date, setDate] = useState(
    moment().subtract(1, "day").format(dataFormatApi)
  );

  const [caseData, setCaseData] = useState();
  const [visible, setVisible] = useState(false);



  const dispatch = useDispatch();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  let reduxEquipmentOverview = useSelector(
    (state) => state.machineLearning.equipmentOverview
  );
  let reduxHealthScore = useSelector(
    (state) => state.machineLearning.healthScore
  );
  let reduxAnomaly = useSelector((state) => state.machineLearning.anomaly);
  let reduxPlantStatus = useSelector(
    (state) => state.machineLearning.plantStatus
  );
  let reduxissuesummary = useSelector(
    (state) => state.machineLearning.IssueSummary
  );

  let reduxDetail = useSelector((state) => state.machineLearning.detail);
  let equipmentid = "";

  let [equipmentOverview, setEquipmentOverview] = useState(null);
  let [healthScore, setHealthScore] = useState(null);
  let [anomaly, setAnomaly] = useState(null);
  let [plantStatus, setPlantStatus] = useState(null);
  let [detail, setDetail] = useState(null);
  let [detailData, setDetailData] = useState(null);
  const [issuesummary, setissuesummary] = useState(null);
  const [issuesummary2, setissuesummary2] = useState(null);

  let [dataFromPlantStatus, setDataFromPlantStatus] = useState(null);
  const [columns, setColumns] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(0);
  const [selectedSort, setSelectedSort] = useState(0);

  const [dis, setdis] = useState(null);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedSensorsData, setSelectedSensorsData] = useState([]);
  const [sensor, setSensor] = useState(null);
  const [sensornew, setSensornew] = useState(null);
  const [datesensor, setDatesensor] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [StateStatus, setStateStatus] = useState(1);
  const [sensorv2, setsensorv2] = useState([]);

  let [anomalyGraph, setAnomalyGraph] = useState(null);
  let [anomalyGraphSum, setAnomalyGraphSum] = useState(null);
  let [Graphtang, setGraphtang] = useState(null);
  const [spinning, setSpinning] = React.useState(false);
  const [equipmentDetail, setEquipmentDetail] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [showrEq, setshowEq] = useState(null);
  let [equid, setEquid] = useState(null);
  const [spinningGraph, setspinningGraph] = useState(null);
  const [loaddata, setloaddata] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [dataloadday, setdataloadday] = useState([]);
  const { Option } = Select;
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [subOptionsVisible, setSubOptionsVisible] = useState(false);
  const [forgraph, setforgarph] = useState(null);
  const [groupdata, setgroupdata] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const reduxSensor = useSelector((state) => state.machineLearning.sensor);
  const reduxSensorSum = useSelector((state) => state.machineLearning.sensorsum);
  const reduxSensorv2 = useSelector((state) => state.machineLearning.SensorV2);
  const reduxSensorGraph = useSelector((state) => state.machineLearning.SensorGraph);
  const reduxRanking = useSelector((state) => state.machineLearning.ranking);

  const reduxruntme = useSelector(
    (state) => state.machineLearning.runtime
  );

  const reduxalert = useSelector(
    (state) => state.machineLearning.alert
  );

  const reduxMlIssue = useSelector(
    (state) => state.machineLearning.mlissue
  );

  const reduxEquip = useSelector(
    (state) => state.machineLearning.equid
  );

  function statusimg(status) {

    switch (status) {
      case 0:
        return New;
      case 1:
        return Assigned;
      case 2:
        return Acknowledge;
      case 3:
        return Pending;
      case 4:
        return Clear;
      default:
        return 'New';
    }
  };

  function statusimg2(status) {

    switch (status) {
      case 0:
        return New2;
      case 1:
        return Assigned;
      case 2:
        return Acknowledge;
      case 3:
        return Pending;
      case 4:
        return Clear;
      default:
        return 'New';
    }
  };
  function statustotext(status) {

    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Assigned';
      case 2:
        return 'Acknowledge';
      case 3:
        return 'Pending';
      case 4:
        return 'Clear';
      default:
        return 'New';
    }
  };

  const handleOpenCase = (obj) => {
    // console.log('handleOpenCase',obj)
    setCaseData(obj);
    setVisible(true);
    dispatch(ServiceGetLogUpdateIssueLast(obj.issue_Id));
  };

  const handleChange = (even) => {
    // console.log("even", typeof (even))
    setStateStatus(parseInt(even, 10))
    //  console.log("StateStatus",parseInt(even, 10));
    dispatch(ServiceGetDateRank(parseInt(even, 10)))


  };



  const itemDistinct = async (item) => {
    //  console.log("item",item)
    // const dis = item.find(x => x.paramDescription) 
    const result = await Array.from(new Set(item.map(s => s.paramId)))
      .map(paramId => {
        return {
          paramId: item.find(s => s.paramId === paramId),
        }
      })

    setdis(result)
    return result
  };

  const dataGraph = async (dataSensor) => {
    setspinningGraph(true);
    // console.log("datasSensrot", dataSensor.length)
    try {
      await setAnomalyGraph(dataSensor);
      setSensornew(dataSensor)
      //  console.log("datasSensrotTRY",dataSensor)
    } catch (error) {
      console.error('Error processing dataGraph:', error);
    } finally {
      setspinningGraph(false);
    }
  };

  useEffect(() => {
    dispatch(
      ServiceEnhGetIssueSummary(
        equipment.plantCode
      )
    );
  }, [equipment.plantCode]);
  //! GET REDUX EQUIPMENT

  useEffect(() => {
    dispatch(
      ServiceGetEquipmentOverviewByPlantEnhance(
        JSON.stringify({ plantCode: equipment.plantCode, date: date })
      )
    );
  }, [equipment.plantCode]);

  //! GET REDUX HEALTHSCORE

  useEffect(() => {
    dispatch(
      ServiceGetHealthScore(
        JSON.stringify({ plantCode: equipment.plantCode, date: date })
      )
    );
  }, [loaddata]); //[date]

  //! GET REDUX SENSOR
  // useEffect(() => {
  //   dispatch(
  //       ServiceGetSensor(
  //           JSON.stringify({
  //             plantCode: equipment.plantCode,
  //             equiP_ID: 1,
  //             date: date,
  //             Day:StateStatus
  //           })
  //       )
  //   );
  // }, [date],[StateStatus]);
  // useEffect(() => {
  //    ServiceEnhGetAllEquip(equipment.plantCode)
  //   // setEquid(reduxEquip)
  //   // setEquid(result.data[0].equipId);
  // }, []);

  useEffect(() => {
    const fetchEquip = async () => {
      try {
        const result = await ServiceEnhGetAllEquip(equipment.plantCode);
        //  console.log('Fetched equipment:', result.data[0].equipId); // Debug log
        //  setEquid(result.data[0].equipId);
        //  console.log('Fetched:', equid); // Debug log
        // setEquid(result.data[0].equipId);

      } catch (error) {
        console.error('Error fetching equipment:', error);
      }
    };

    dispatch(ActionClearSensorSum());
    dispatch(ActionClearRanking());
    dispatch(ActionClearByparam());

    fetchEquip();

  }, [equipment.plantCode])

  //! GET REDUX Ranking
  useEffect(() => {
    const fetchEquip = async () => {
      try {
        setSpinning(true);
        // console.log("equipment.plantCode",equipment.plantCode)
        const result = await ServiceEnhGetAllEquip(equipment.plantCode);
        //  setEquid(result.data[0].equipId);
        console.log("result",result)
        const payload = JSON.stringify({
          plantCode: equipment.plantCode,
          equiP_ID: result.data[0].equipId,
          date: date,
          Day: StateStatus
        });

        // dispatch(ServiceGetRanking(payload));
        // dispatch(ServiceGetSensor(payload));
        // dispatch(ServiceGetSensorSum(payload));
        console.log("LOOP1")
        dispatch(ServiceGetSensorV2(payload));
        // dispatch(ServiceGetSensorV2(payload));
        await setsensorv2(reduxSensorv2);


        const equipId = result.data[0].equipId;
        setAnomalyGraphSum(null);

        //  setStateOpenKeys([String(equipId)]);
        //  setEquid(result.data[0].equipId);

      } catch (error) {
        console.error('Error fetching equipment:', error);
      }
      setSpinning(false);
    };

    fetchEquip();
    // setStateOpenKeys([equid]);
    // if(equid == 1) {
    // console.log('Dispatching actions with equid:', equipment.plantCode);
    //    else {
    //     console.log('Equid is not ready or empty'); // Debug log
    // }
    //     dispatch(
    //       ServiceGetRanking(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );
    //   dispatch(
    //       ServiceGetSensor(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );
    //   dispatch(
    //     ServiceGetSensorSum(
    //         JSON.stringify({
    //           plantCode: equipment.plantCode,
    //           equiP_ID: equid[0].equipId,
    //           date: date,
    //           Day:StateStatus
    //         })
    //     )
    // );
    //   dispatch(
    //       ServiceGetSensorV2(
    //           JSON.stringify({
    //             plantCode: equipment.plantCode,
    //             equiP_ID: equid[0].equipId,
    //             date: date,
    //             Day:StateStatus
    //           })
    //       )
    //   );


    // setStateOpenKeys([reduxSensorv2[0].key]);
    // setequit(reduxSensorv2[0].key);
  }, [equipment.plantCode]); //[date]

  useEffect(() => {
    dispatch(
      ServiceGetIssure(
        JSON.stringify({
          plantCode: equipment.plantCode,
          equiP_ID: 1,
          date: date,
        })
      )
    );
  }, [loaddata]); //[date]

  useEffect(() => {
    const fetchData = async () => {
      console.error("reduxSensor")
      try {

        if (Array.isArray(reduxSensor) && reduxSensor.length > 0 && reduxSensor != null) {
          setSensor(reduxSensor);


          await itemDistinct(reduxSensor);
          // setEquipmentDetail(reduxSensor);

          //  await  setGraphtang(reduxRanking);
          // setshowEquip = reduxSensor[0].equipNameDisplay;
          // setShowplan= reduxSensor[0].plantCode;
          //  console.log("AAAAA",reduxSensor[0].equipNameDisplay)
        }
      } catch (error) {
        console.error('Error handling sensor data:', error);
      } finally {
        // setSpinning(false); 

      }
    };

    fetchData();
  }, [date, reduxSensor]);
  // console.log("spin593",spinning)

  useEffect(() => {
   const factdatasensor = async () => {
    // setSpinning(true);
    if (Array.isArray(reduxSensorv2) && reduxSensorv2.length > 0 && reduxSensorv2 != null) {
      // setSpinning(false);
      await setsensorv2(reduxSensorv2);
        // console.log("LoopsetSensorv2",reduxSensorv2)

    }
   };
   factdatasensor();

  }, [reduxSensorv2]);
  // console.log("spin604",spinning)

  useEffect(() => {

    if (Array.isArray(reduxSensorSum) && reduxSensorSum.length > 0 && reduxSensorSum != null) {

      setAnomalyGraphSum(reduxSensorSum);
      // setEquipmentDetail(reduxSensorSum);
      // setGraphtang(reduxRanking);
      // dataGraph(reduxSensorSum)
      // console.log("reduxSensorSum581",reduxSensor)


    }

  }, [reduxSensorSum]);

  useEffect(() => {
    const fetchData = async () => {
      await setGraphtang(reduxRanking);
      // console.log("reduxRanking", reduxRanking)
    };
    fetchData();
  }, [reduxRanking]);


  // useEffect(() => {
  //   if (Array.isArray(dis) && dis.length > 0 && dis != null) {
  //       console.log("doit",dis)
  //     setSelectedSensorsData(
  //       selectedSensors.map((item) => {
  //         return dis[item].paramId.paramId;
  //       })
  //     );
  //   }
  //    console.log("karun",selectedSensorsData)
  //    console.log("karun2",reduxSensor)
  //    console.log("karun3",dis)
  // }, [dis,reduxSensorv2]);


  //! GET INITIAL ANOMALY

  useEffect(() => {
    if (reduxHealthScore !== null) {
      if (reduxHealthScore.length !== 0) {
        (async () => {
          await dispatch(
            ServiceGetAnomaly(
              JSON.stringify({
                plantCode: equipment.plantCode,
                date: date,
                equiP_ID: reduxHealthScore[0].equipId,
              })
            )
          );
          setIsLoadingAnomaly(false);
        })();
      } else {
        dispatch(ActionClearAnomaly());
      }
    } else {
      dispatch(ActionClearAnomaly());
    }
  }, []);


  useEffect(() => {
    setEquipmentOverview(reduxruntme);
  }, [reduxruntme]);

  useEffect(() => {
    dispatch(ServiceGetRumtime());
    dispatch(ServiceGetAlert());
    dispatch(ServiceGetAlert())
    setIsLoading(false);
    setissuesummary(reduxMlIssue);
    showdata(reduxMlIssue);
    setshowEq(reduxalert);

    //  console.log("reduxMlIssue", reduxMlIssue);
    //  console.log("reduxruntmerERE",reduxruntme);
    //  console.log("equipment.plantCode",equipment.plantCode);

  }, [reduxMlIssue]);

  // useEffect(() => {
  //   const data = async() => {

  //     const dataalert = 
  //     console.log("SDFF",dataalert)
  //     setshowEq(dataalert)
  //   }

  //   data();
  // },[reduxMlIssue])

  useEffect(() => {
    if (reduxHealthScore != null) {
      if (reduxHealthScore.length !== 0) {
        if (selectedSort == 0) {
          reduxHealthScore.sort((a, b) => b.scoreOutliner - a.scoreOutliner);
          setHealthScore(reduxHealthScore);
        }
        if (selectedSort == 1) {
          reduxHealthScore.sort((a, b) =>
            a.equipmentName.localeCompare(b.equipmentName)
          );
          setHealthScore(reduxHealthScore);
        }
      }
    }

    setHealthScore(reduxHealthScore);
  }, [reduxHealthScore]);
  useEffect(() => {
    setAnomaly(reduxAnomaly);
  }, [reduxAnomaly]);
  useEffect(() => {
    setPlantStatus(reduxPlantStatus);
    if (reduxPlantStatus !== null) {
      if (reduxPlantStatus.length > 0) {
        setDataFromPlantStatus(reduxPlantStatus[reduxPlantStatus.length - 1]);
      }
    }
  }, [reduxPlantStatus]);

  useEffect(() => {
    if (healthScore  && healthScore[selectedCategories]) {
      if (healthScore[selectedCategories].equipId) {
        (async () => {
          await dispatch(
            ServiceGetAnomaly(
              JSON.stringify({
                plantCode: equipment.plantCode,
                date: date,
                equiP_ID: healthScore[selectedCategories].equipId,
              })
            )
          );
          setIsLoadingAnomaly(false);
        })();
      }
    }
  }, [healthScore]);

  useEffect(() => {
    console.log("SensorGraph", reduxSensorGraph);
    console.log("graphData", graphData);

    if (reduxSensorGraph && reduxSensorGraph.length > 0 && reduxSensor && reduxSensor.length > 0) { 
        const paramCode = reduxSensorGraph[0].paramCode;

        if (selectedSensorsData.includes(paramCode)) {
            // อัปเดต state เฉพาะเมื่อจำเป็น
            if (selectedSensorsData.length > 0) {
                setSelectedSensorsData(selectedSensorsData.filter(i => i !== paramCode));
            }
        } else {
            setSelectedSensorsData([...selectedSensorsData, paramCode]);
            console.log("selectedSensorsDataIF", paramCode);
        }

        console.log("pramidqqq", paramCode);

        if (reduxSensorGraph && reduxSensorGraph.length > 0) {
            const dataloadday1 = [paramCode, reduxSensorGraph[0].equipId,reduxSensorGraph[0].paramId];
            // console.log("reduxSensorGraph",reduxSensorGraph);
            // console.log("dataloadday1",dataloadday1)

            setdataloadday(prevDataloadday => {
                // ตรวจสอบว่าข้อมูลไม่ซ้ำก่อนอัปเดต
                if (Array.isArray(prevDataloadday) && !prevDataloadday.includes(dataloadday1)) {
                    return [...prevDataloadday, dataloadday1];
                } else {
                    console.error("prevDataloadday is not an array or already includes data");
                    return prevDataloadday;
                }
            });

            // console.log("setdataloadday", dataloadday1);
        } else {
            console.error("pramid is undefined or empty");
        }
    } else {
        console.error('Error fetching data Loop1:');
    }
}, [forgraph, reduxSensorGraph]);

//! LOOP GRAPHSENSOR
  useEffect(() => {
    // console.log("reduxSensorGraphIF", graphData)
    if (reduxSensorGraph !== null) {
      if (graphData && graphData.length > 0) {
        const updatedGraphData = [...graphData, ...reduxSensorGraph];
        setGraphData(updatedGraphData);
        dataGraph(updatedGraphData);
          // console.log("reduxSensorGraphIF", updatedGraphData);
      } else {
        setGraphData(reduxSensorGraph);
        dataGraph(reduxSensorGraph);
        //  console.log("reduxSensorGraphElse", reduxSensorGraph);
      }
    } else {
      console.error('Error: reduxSensorGraph is empty or null.');
    }

  }, [reduxSensorGraph])

  const success = () => {
    messageApi.open({
      type: 'warning',
      content: 'NO DATA IN TODAY',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
  };

  const showdata = async (dataIssue) => {
    let dataalert = 0;
    let datawarnig = 0;
    let datanormal = 0;

    // console.log("dataIssue",dataIssue)
    for (let i in dataIssue) {
      // console.log("dataIssue",i)

      if (dataIssue[i].alertstate == 2) {
        dataalert = dataalert + 1;
      }
      else {
        datawarnig = datawarnig + 1;
      }

    }
  }

  //!GetdataSensor PARAMID
  const getdataSensorparamid = async(paramID) => {
    const pramid = paramID;
      (async () => {
        try {
          setIsLoading(true);
          await dispatch(ServiceEnhGetSensorByparam(
            JSON.stringify({
              plantCode: equipment.plantCode,
              equiP_ID: equid,
              date: date,
              Day: StateStatus,
              paramId: pramid
            })
          ));
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      })();
}



  const onChange = (key) => {
    console.log(`selected ${key}`);
    // setShowplan(1);
    
    setEquipmentDetail(reduxSensor);
    console.log("FFFFF", reduxSensor)
  };

  const handleMainSelectChange = async (value) => {
    // console.log("OnSelectSensor",value)
    setIsLoading(true)
    setSelectedSensorsData([])
    setEquid(value);
    setgroupdata(value.toString())
    const getdataSensorGraph = async() => {
              const payloadGraph = JSON.stringify({
                    plantCode: equipment.plantCode,
                    date: date,
                    equiP_ID: value,
                    Day: StateStatus
              });
              const payloadSensor = JSON.stringify({
                plantCode: equipment.plantCode,
                equiP_ID: value,
                date: date,
                Day: StateStatus
          });

          dispatch(ServiceGetSensorSum(payloadGraph));
          dispatch(ServiceGetRanking(payloadGraph));
          dispatch(ServiceGetSensor(payloadSensor));
          dispatch(ServiceGetSensorV2(payloadSensor));
         
      }
    
    await getdataSensorGraph();
    setSpinning(false)
    setSelectedGroup(group);
    setSubOptionsVisible(true);
    setIsLoading(false)
    //  const group = reduxSensorv2?.find((item) => item.value === equid);

  };

  const group = reduxSensorv2?.find((item) => item.value == equid);
  // console.log("Group1126",group)

  const handleSubSelectChange = (value) => {
    console.log('Selected sub-option:', value);
  };

  const showGraph = async(item) => {
    console.log("Kettet",item)

    const pramid = reduxSensor.filter(sensor => sensor.paramCode === item.key);
    // console.log("pramid",pramid)  
    
      if (pramid.length > 0) {
        (async () => {
          try {
            setIsLoading(true);
            await dispatch(ServiceEnhGetSensorByparam(
              JSON.stringify({
                plantCode: equipment.plantCode,
                equiP_ID: pramid[0].equipId,
                date: date,
                Day: StateStatus,
                paramId: pramid[0].paramId
              })
            ));
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        })();
      }       
      //  console.log("itemID", item)
          setforgarph(pramid[0].paramId)
  }

  const handleShowGraph = useCallback((item) => {
    console.log("item1", item)
    console.log("selectedSensorsData1", selectedSensorsData)
    console.log("setSelectedSensorsData1", setSelectedSensorsData)
    showGraph(selectedSensorsData, setSelectedSensorsData, item);
  }, [selectedSensorsData]);


  const changDate = (selcetdate) => {
    (async () => {
      // console.log("Data",selcetdate,equipmentid)
      // if (equipmentid == "") {
      //   equipmentid = sensorv2[0].key;
      // }
      // console.log("equipmentid",equipmentid)
      await dispatch(
        ServiceGetSensor(
          JSON.stringify({
            plantCode: equipment.plantCode,
            equiP_ID: equipmentid,
            date: selcetdate,
            Day: StateStatus
          })
        ))
    })();
  }

  const handleSelectedCategories = (index, id) => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedCategories(index);
    (async () => {
      await dispatch(
        ServiceGetAnomaly(
          JSON.stringify({
            plantCode: equipment.plantCode,
            date: date,
            equiP_ID: id,
          })
        )
      );
      setIsLoadingAnomaly(false);
      // await dispatch(
      //   ServiceGetPlantStatus(
      //     JSON.stringify({
      //       plantCode: equipment.plantCode,
      //       date: date,
      //       equiP_ID: id,
      //     })
      //   )
      // );
      // setIsLoadingPlantStatus(false);
    })();
  };
  const handleDateChange = async (date) => {
    setDate(date.format(dataFormatApi));
    dispatch(ServiceGetDate(date.format(dataFormatApi)))
    setOpen1(false);
    setOpen2(false);
  };
  const handleDateChange1 = async () => {
    try {
      setAnomalyGraph(null);
      setAnomalyGraphSum(null);
      setSpinning(true)
      setIsLoading(true);

      // Fetch ServiceGetRanking
      await dispatch(ServiceGetRanking(
        JSON.stringify({
          plantCode: equipment.plantCode,
          date: date,
          equiP_ID: equid,
          Day: StateStatus
        })
      ));

      // Fetch ServiceGetSensorSum
      await dispatch(ServiceGetSensorSum(
        JSON.stringify({
          plantCode: equipment.plantCode,
          date: date,
          equiP_ID: equid,
          Day: StateStatus
        })
      ));

      // Loop through dataloadday and fetch ServiceEnhGetSensorByparam
      // console.log("dataloadday",dataloadday)
      for (const element of dataloadday) {
        console.log("element",element)
        dispatch(ServiceEnhGetSensorByparam(
          JSON.stringify({
            plantCode: equipment.plantCode,
            equiP_ID: element[1],
            date: date,
            Day: StateStatus,
            paramId: element[2]
          })
        ));
      }
      setdataloadday(StateStatus)
    } catch (error) {
      console.error('Error in async actions:', error);
    } finally {
      // console.log("finall")

      dispatch(ActionClearByparam());
      setIsLoading(false);
    setSpinning(false);
    }
    
  };
  const handleSortByScoreClick = () => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedSort(0);
    setHealthScore((prevData) => sortDataByScoreOutliner([...prevData]));
  };

  const handleSortByAZClick = () => {
    setIsLoadingAnomaly(true);
    setIsLoadingPlantStatus(true);
    setIsLoadingDetail(true);
    setSelectedSort(1);
    setHealthScore((prevData) => sortDataByEquipmentName([...prevData]));
  };

  const PlantStatusClickHandler = (target) => {
    setDataFromPlantStatus(target._dataItem.dataContext);
  };

  const handleItemClick = (itemIndex, paramid) => {
    // console.log("handleItemClick",itemIndex,paramid,selectedSensors)
    if (selectedSensors.includes(itemIndex)) {
      setSelectedSensors(
        selectedSensors.filter((index) => index !== itemIndex)
      );
    } else {
      setSelectedSensors([...selectedSensors, itemIndex].sort((a, b) => a - b));
    }
  };
  const handleItemClick2 = (itemIndex, paramid) => {
    // console.log("handleItemClick2",itemIndex,selectedSensors)
    if (selectedSensors.includes(itemIndex)) {
      setSelectedSensors(
        selectedSensors.filter((index) => index !== itemIndex)
      );
    } else {
      setSelectedSensors([...selectedSensors, itemIndex].sort((a, b) => a - b));
    }
  };

  const showModal = async (equipname, index) => {
    // plantOverview
    // .filter((e) => e.plantGroup === "12SPPs")
    let equid = issuesummary.filter((e) => e.issue_Id === equipname)
    const payload = JSON.stringify({
      plantCode: equipment.plantCode,
      equiP_ID: equid[0].equid,
      date: date,
      Day: 1
    });

    // console.log("equipname",equipname)
    // console.log("issuesummary",payload)

    let filter;
    await ServiceGetIssurebyEquipID(payload).then(response => {
      filter = response;
      // console.log("filter",filter.data);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });

    // let filter =  issuesummary.filter((e) => e.issue_Id === equipname)

    setissuesummary2(filter.data);
    // console.log("filter",filter)
    // setfiterissue()
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cleardata = () => {
    setAnomaly(null);
    setAnomalyGraph(null);
    setAnomalyGraphSum(null);
  }

  const calldataGraoh = async (equipID) => {
    console.log("DataEquipID", equipID);
    setgroupdata(equipID.toString());
    handleMainSelectChange(equipID);
    setSelectedSensorsData([])

  }

  function getPlantCode(paramId, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].paramCode === paramId) {
        return `${dataArray[i].paramDescription} : ${dataArray[i].paramCode}(${dataArray[i].paramUnit})`
      }
    }
    return null;
  }

  // const setColumnsDetail = () => {
  //   let col = [
  //     {
  //       title: () => <p style={styleTable.text}>ITEM</p>,
  //       dataIndex: "item",
  //       key: "item",
  //       fixed: "left",
  //       width: 130,

  //       render: (text) => <p style={styleTable.hText}>{text}</p>,
  //     },
  //   ];
  //   for (let i = 0; i < detail.length; i++) {
  //     const element = {
  //       title: () => (
  //         <Tooltip placement="topLeft" title={detail[i].paramCode}>
  //           {" "}
  //           <p style={styleTable.text}>{`${
  //             detail[i].paramDescription
  //           } (${detail[i].paramUnit.replace("deg", "°")})`}</p>
  //         </Tooltip>
  //       ),
  //       dataIndex: `${detail[i].paramCode}`,
  //       key: `${detail[i].paramCode}`,
  //       render: (text) => <p>{text}</p>,
  //     };
  //     col.push(element);
  //   }
  //   setColumns([...col]);
  // };
  // const setRowsDetail = () => {
  //   if (detail.length > 0) {
  //     const row = [
  //       {
  //         item: "Actual",
  //         name: "actual",
  //       },
  //       {
  //         item: "Nearest",
  //         name: "nearest",
  //       },
  //       {
  //         item: "Diff",
  //         name: "diff",
  //       },
  //       {
  //         item: "%Diff",
  //         name: "diffPercent",
  //       },
  //       {
  //         item: "Weight (%)",
  //         name: "weight",
  //       },
  //     ];
  //     for (let i = 0; i < row.length; i++) {
  //       if (detail.length > 0) {
  //         for (let o = 0; o < detail.length; o++) {
  //           row[i][`${detail[o].paramCode}`] = detail[o][`${row[i].name}`];
  //         }
  //       }
  //     }
  //     setDetailData([...row]);
  //   }
  //   setIsLoadingDetail(false);
  // };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }
  function formatDateWithTime(isoDateString) {
    // Create a new Date object from the string
    let date = new Date(isoDateString);

    // Define month names
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract components of the date
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Pad minutes with leading zeros if necessary
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    // Combine the parts into a formatted string
    return `${monthNames[monthIndex]} ${day}, ${year} ${hours}:${minutes}`;
  }

  function replaceCharInString(string) {
    const search = '_';
    const searchRegExp = new RegExp(search, 'g');
    const replaceWith = ' ';

    const result = string.replace(searchRegExp, replaceWith)

    return result;
  }

  return (
    <>
      <MenuComponent {...props}></MenuComponent>
      <section className="eq_container clearfix">
        <div className="eq_back clearfix">
          <Link to="/" onClick={cleardata} className="eq_back_link">
            Home
          </Link>

          <span className="eq_breadcum eq_breadcum_tar">Equipment Status</span>
        </div>
        <div className="eq_top_img" data-parallax='{"x": -180}'>
          <img src={EquipPic} className="img_equip_pic" />
        </div>
        <div className="eq_top_sect clearfix">
          <div className="eq_content clearfix">
            <div className="eq_sentence">
              <div className="eq_sentence_title">
                <br />
                <h1 className="txt_equip_title">Equipment Status</h1>
              </div>
              <div className="eq_sentence_cont">
                Machine learning is a branch of artificial intelligence (AI) and
                computer science which focuses on the use of data and
                algorithms.
              </div>
            </div>
          </div>
          <div className="eq_sum_issue clearfix">
            {/* <div className="eq_label clearfix">Issue summary</div> */}
            <div className="eq_summary_sec clearfix">
              {/*bar*/}
              <div className="eq_sum_bar clearfix">
                <div className="eq_sum_chart clearfix">
                  {reduxruntme ? (
                    reduxruntme.filter((e) => e.plantCode === equipment.plantCode)
                      .map((obj, index) => (
                        <Fragment key={index}>
                          { obj.plantGroup == 'IPPs' ?
                          (
                            <>
                            <div
                            className={`ml_chart_nor h${Math.round((obj.normal * 100) /  (obj.normal + obj.warning + obj.alert)) 

                              } n${obj.normal}`}></div>
                          <div
                            className={`ml_chart_warn h${Math.round((obj.warning * 100) /  (obj.normal + obj.warning + obj.alert))
                              } n${obj.warning}`}></div>
                          <div
                            className={`ml_chart_alert h${Math.round((obj.alert * 100) /  (obj.normal + obj.warning + obj.alert))

                              } n${obj.alert}`}></div>
{/* 
                          <div
                            className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) /  (obj.normal + obj.warning + obj.alert))) - Math.round((obj.warning * 100) /  (obj.normal + obj.warning + obj.alert)) - Math.round((obj.alert * 100) /  (obj.normal + obj.warning + obj.alert))
                              } n${8 - (obj.normal - obj.warning - obj.alert)}`}></div> */}
                            </>
                          ):
                          (
                            <>
                            <div
                            className={`ml_chart_nor h${Math.round((obj.normal * 100) / (obj.normal + obj.warning + obj.alert)) 

                              } n${obj.normal}`}></div>
                          <div
                            className={`ml_chart_warn h${Math.round((obj.warning * 100) / (obj.normal + obj.warning + obj.alert))
                              } n${obj.warning}`}></div>
                          <div
                            className={`ml_chart_alert h${Math.round((obj.alert * 100) / (obj.normal + obj.warning + obj.alert))

                              } n${obj.alert}`}></div>

                          <div
                            className={`ml_chart_off h${(100 - Math.round((obj.normal * 100) / (obj.normal + obj.warning + obj.alert))) - Math.round((obj.warning * 100) / (obj.normal + obj.warning + obj.alert)) - Math.round((obj.alert * 100) / (obj.normal + obj.warning + obj.alert))
                              } n${(obj.normal + obj.warning + obj.alert) - obj.normal - obj.warning - obj.alert}`}></div>
                            </>
                          )
                          }
                          
                          {/*<div*/}
                          {/*  className={`ml_chart_off h${Math.round((equipmentOverview.grey * 100) / equipmentOverview.total)*/}

                          {/*    } n${equipmentOverview.grey}`}></div>*/}


                        </Fragment>
                      ))
                  ) : (
                    <div></div>

                  )}
                  <span className="text-span"> Runtime </span>
                </div>
              </div>

              <div className="eq_sum_bar clearfix">
                <div className="eq_sum_chart clearfix">
                  {reduxalert ? (
                    reduxalert.filter((e) => e.plantCode === equipment.plantCode)
                      .map((obj, index) => (

                        <Fragment key={index}>
                              <>
                            <div
                              className={`ml_chart_nor h${Math.round((obj.normal * 100) / (obj.normal + obj.warning + obj.alert))
  
                                } n${obj.normal}`}></div>
                            <div
                              className={`ml_chart_warn h${Math.round((obj.warning * 100) / (obj.normal + obj.warning + obj.alert))
  
                                } n${obj.warning}`}></div>
                            <div
                              className={`ml_chart_alert h${Math.round((obj.alert * 100) / (obj.normal + obj.warning + obj.alert))
  
                                } n${obj.alert}`}></div>
                              </>
                        </Fragment>
                      ))
                  ) : (
                    <></>
                  )}
                  <span className="text-span"> Alert </span>
                </div>
              </div>

              {/*bar*/}

              <div className="eq_sum_data clearfix">
                {reduxruntme ? (
                  reduxruntme.filter((e) => e.plantCode === equipment.plantCode)
                    .map((obj, index) => (
                      <Fragment key={index}>
                        <div className="eq_sum_data_title clearfix">
                          <span className="txt_h4txt_plant">{obj ? obj.plantGroup : ""} </span>
                          <br />
                          <span className="txt_equip_plant">
                            {obj ? obj.plantCode : ""}
                          </span>
                          {/* <form>
                        <div className="cate_date_opt_txt issue_summary">
                          Select Date :{" "}
                          <span className="co_wh">
                            <div className="graph_equip_search">
                              <DatePicker
                                className="graph_equip_date"
                                format={dateFormat}
                                onChange={handleDateChange}
                                onClick={() => setOpen1(!open1)}
                                defaultValue={moment().subtract(1, "day")}
                                value={
                                  date
                                    ? moment(date, dateFormat)
                                    : moment("", dateFormat)
                                }
                                open={open1}
                              />
                            </div>
                          </span>
                          <span>
                            <i
                              className="icon-calendar1 calend_ico"
                              onClick={() => setOpen1(!open1)}
                            />
                          </span>
                        </div>
                      </form> */}
                        </div>
                        <div className="eq_sum_data_cont clearfix">
                          <div className="eq_sum_data_box eq_sum_space">
                            <span className="eq_sum_ico">
                              <img src={DateIco} className="img_analytic" />
                            </span>
                            <span className="eq_sum_cont txt_h2txt_result">
                              {obj ? (
                                formatDate(today)
                              ) : (
                                <>-</>
                              )}
                            </span>
                            <span className="eq_sum_cont txt_h5txt_title">Date</span>
                          </div>
                          <div className="eq_sum_data_box eq_sum_space">
                            <span className="eq_sum_ico">
                              <img src={RuntimeIco} className="img_analytic" />
                            </span>
                            <span className="eq_sum_cont txt_h2txt_result co_run">
                              {obj.normal}
                            </span>
                            <span className="eq_sum_cont txt_h5txt_title">Normal</span>
                          </div>
                          <div className="eq_sum_data_box eq_sum_space">
                            <span className="eq_sum_ico">
                              <img src={WarningIco} className="img_analytic" />
                            </span>
                            <span className="eq_sum_cont txt_h2txt_result co_warning">
                              {obj ? obj.warning : "-"}
                            </span>
                            <span className="eq_sum_cont txt_h5txt_title">Warning</span>
                          </div>
                          <div className="eq_sum_data_box eq_sum_space">
                            <span className="eq_sum_ico">
                              <img src={AlertIco} className="img_analytic" />
                            </span>
                            <span className="eq_sum_cont txt_h2txt_result co_alert">
                              {obj ? obj.alert : "-"}
                            </span>
                            <span className="eq_sum_cont txt_h5txt_title">Alert</span>
                          </div>

                          <div className="eq_sum_data_box eq_sum_space">
                            <span className="eq_sum_ico">
                              <img src={OffIco} className="img_analytic" />
                            </span>
                            <span className="eq_sum_cont txt_h2txt_result co_off">
                              {obj ? 0 : "-"}
                            </span>
                            <span className="eq_sum_cont txt_h5txt_title">Off</span>
                          </div>
                        </div>


                      </Fragment>
                    ))
                ) : (
                  <> </>

                )}
              </div>
            </div>
          </div>
          {/* Equipment status Summart OLD */}
          <div className="eq_sum_issue clearfix">
            <div className="issue_content clearfix">
              <div className="issue_l txt_h4txt_w clearfix"> EQUIPMENT STATUS SUMMARY </div>
              <div className="issue_date_r clearfix">
                <i className="icon-file-text" />{" "}

                <Link to="/issue_summary">View all</Link>
              </div>
            </div>
            <div className="issue_table clearfix">
              {/* Table Title */}
              <div className="issue_th th_row clearfix">
                <div className="issue_th txt_th1 clearfix">
                  <div className="issue_td td_eqip clearfix">Equipment</div>
                  <div className="issue_td td_btn clearfix">Runtime</div>
                  <div className="issue_td td_btn clearfix">Alert State</div>
                  <div className="issue_td_opc td_btn clearfix">OPEN CASE</div>
                  <div className="issue_td_pty td_btn clearfix">Priority</div>
                  <div className="issue_td td_btn clearfix">Case Status</div>
                  <div className="issue_td td_eqip clearfix">ASSIGNED TO</div>
                </div>
              </div>
              {/* Table Title */}
              {/* Table Row */}
              {issuesummary ? (
                issuesummary.map((obj, index) => (

                  <div className="issue_th th_brow clearfix" key={index}>
                    <div className="issue_th txt_td1 clearfix">
                      <Link onClick={() => calldataGraoh(obj.equid)}> <div className="issue_td td_eqip clearfix">
                        {obj.equip_Name}
                      </div> </Link>

                      <div className="issue_td td_btn tooltip clearfix">
                        <img
                          src={getDataRuntime(obj.runtime).image}
                          className="btn_status"
                          alt="s"
                        />
                        <span className="tooltiptext">
                          {getDataRuntime(obj.runtime).name}
                        </span>
                      </div>

                      <div className="issue_td td_btn clearfix">
                        <div className="tooltip">
                          <img
                            src={getDataAlert(obj.alertstate).image}
                            className="btn_status"
                            alt=""
                          />
                          <span className="tooltiptext">
                            {getDataAlert(obj.alertstate).name}
                          </span>
                        </div>
                      </div>
                      <div className="issue_td_opc td_btn clearfix">
                        {obj.issue_Id === 999 ? 0 : obj.opencase}
                      </div>

                      <div className="issue_td_pty td_btn clearfix">
                        <div className="tooltip">
                          <img
                            src={getDataPriorty(obj.issuePriority).image}
                            className="btn_status"
                            alt=""
                          />
                          <span className="tooltiptext">
                            {getDataPriorty(obj.issuePriority).name}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`issue_td td_btn tooltip clearfix ${obj.issue_Id === 999 ? 'disabled' : ''}`}
                        onClick={() => obj.issue_Id !== 999 && showModal(obj.issue_Id, index)}
                        style={{ cursor: obj.issue_Id === 999 ? 'not-allowed' : 'pointer', opacity: obj.issue_Id === 999 ? 0.5 : 1 }}
                      >
                        <img src={statusimg(obj.casestatus)} className="btn_a btn_status" alt="" />
                        <span className="tooltiptext">{statustotext(obj.casestatus)}</span>
                      </div>
                      {/* <div
                          className="issue_td td_btn tooltip clearfix"
                          onClick={() => showModal(obj.issue_Id, index)}>
                        <img src={statusimg(obj.casestatus)} className="btn_a btn_status" alt=""/>
                        <span className="tooltiptext">{statustotext(obj.casestatus)}</span>
                      </div> */}

                      <div className="issue_td td_eqip clearfix">
                        {obj.assignedTo}
                      </div>

                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {/* Table Row */}
            </div>
          </div>
          {/* Equipment status Summart NEW */}
          <Modal title="EQUIPMENT STATUS SUMMARY"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>]}
            width={1000} style={{
              top: 20,
            }}>
            <div className="eq_sum_issue clearfix">
              <div className="issue_content clearfix">
                <div className="issue_l txt_h4txt_w clearfix"> EQUIPMENT STATUS SUMMARY </div>
                <div className="issue_date_r clearfix">
                  <i className="icon-file-text" />{" "}

                  <Link to="/issue_summary">View all</Link>
                </div>
              </div>
              <div className="issue_table clearfix">
                {/* Table Title */}
                <div className="issue_th th_row clearfix">
                  <div className="issue_th txt_th1 clearfix">
                    <div className="issue_td td_date clearfix">Issue Id</div>
                    <div className="issue_td td_date clearfix">Date</div>
                    <div className="issue_td td_eqip clearfix">Equipment</div>
                    <div className="issue_td td_btn clearfix">Runtime</div>
                    <div className="issue_td td_btn clearfix">Alert State</div>
                    <div className="issue_td_pty td_btn clearfix">Priority</div>
                    <div className="issue_td td_btn clearfix">Case Status</div>
                  </div>
                </div>
                {/* Table Title */}
                {/* Table Row */}
                {issuesummary2 ? (
                  issuesummary2.map((obj, index) => (

                    <div className="issue_th th_brow clearfix" key={obj.index}>
                      <div className="issue_th txt_td1 clearfix">
                        <div className="issue_td td_plant clearfix">
                          {obj.issue_Id}
                        </div>
                        <div className="issue_td td_date clearfix">
                          {formatDate(obj.issue_DateTime)}
                        </div>
                        <div className="issue_td td_eqip clearfix">
                          {obj.equip_Name}
                        </div>

                        <div className="issue_td td_btn tooltip clearfix">
                          <img
                            src={getDataRuntime(obj.runtime).image}
                            className="btn_status"
                            alt="s"
                          />
                          <span className="tooltiptext">
                            {getDataRuntime(obj.runtime).name}
                          </span>
                        </div>

                        <div className="issue_td td_btn clearfix">
                          <div className="tooltip">
                            <img
                              src={getDataAlert(obj.alertstate).image}
                              className="btn_status"
                              alt=""
                            />
                            <span className="tooltiptext">
                              {getDataAlert(obj.alertstate).name}
                            </span>
                          </div>
                        </div>

                        <div className="issue_td_pty td_btn clearfix">
                          <div className="tooltip">
                            <img
                              src={getDataPriorty(obj.issuePriority).image}
                              className="btn_status"
                              alt=""
                            />
                            <span className="tooltiptext">
                              {getDataPriorty(obj.issuePriority).name}
                            </span>
                          </div>
                        </div>

                        <div
                          className="issue_td td_btn tooltip clearfix"
                          onClick={() => handleOpenCase(obj)}>
                          <img src={statusimg2(obj.casestatus)} className="btn_a btn_status" alt="" />
                          <span className="tooltiptext">{statustotext(obj.casestatus)}</span>
                        </div>


                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {/* Table Row */}
              </div>
            </div>
          </Modal>
          {/* Equipment status Summart NEW */}


          <div className="eq_top_bg clearfix" />
        </div>
        {/*.................. NEW PAGE ................*/}
        <div>
          <section className="data_container eq_b32 clearfix">
            <Spin spinning={spinning} tip="Loading..." size="large" className="contentStyle" fullscreen="true">
              {/* <div className="ad_subject_title clearfix">
                <div className="eq_sentence">
                  <h2 className="txt_h3txt_head">Detail Of System</h2>
                </div>
              </div> */}
              <div className="eq_details_container clearfix">
                <div className="ad_container_sect clearfix">
                  {/* Details */}
                  <div className="ad_container_l clearfix">
                    {/* <div className="ad_hc_tools clearfix">
                      <div className="ad_hc_title">Health Score</div>
                      <div className="ad_hc_chart"> */}
                    {/* {equipmentDetail ? (
                          <HealthScoreGauge
                            healthScore={equipmentDetail[0]}
                            textColor={"rgb(255,255,255)"}></HealthScoreGauge>
                        ) : (
                          <></>
                        )} */}

                    {/* <img src={HalfChart2} className="img_adhs_chart" /> */}
                    {/* </div>
                    </div> */}
                    <div className="ad_hc_tools clearfix">
                      <div className="ad_hc_title alig_cen">Sensors Detection</div>
                      <div className="ad_hc_navlist clearfix">
                        {reduxSensorv2 ? (
                          <>
                            <Select
                              style={{ 
                                  width: '250px',
                                  marginBottom: '10px',
                                  textAlign: 'center',
                                  color: 'white',
                                  }}
                              placeholder="Select an Equipment"
                              value={groupdata}
                              onChange={handleMainSelectChange}
                              allowClear
                            >
                              {reduxSensorv2.map((group) => (
                                <Option key={group.value} value={group.value}>
                                  {group.label}
                                </Option>
                              ))}
                              
                            </Select>
                                {/* {JSON.stringify(group)} */}
                            {group && (
                              <div style={{ 
                                      marginTop: '10px',
                               }}>
                                {/* <h3>Sub-options for {selectedGroup.label}:</h3> */}
                            <div  style={{
                                height: 600,
                                overflow: 'auto',
                                padding: '0 16px',
                               
                              }}>
                                <List
                                  bordered
                                  dataSource={group.children}
                                  style={{
                                    width: '250px',
                                      marginBottom: '10px',
                                      textAlign: 'center',
                                      color: 'white',
                                      border: '0px',
                                      borderbottom: '0px'
                                  }}
                                  renderItem={(item) => (
                                    <List.Item 
                                      key={item.paramID}
                                      onClick={() => showGraph(item)}
                                      style={{ cursor: 'pointer',
                                         color: selectedSensorsData.find((data) => data === item.key) ? '#CCFF66' : '#DCDCDC',
                                         paddingLeft: "0px",
                                         whiteSpace: "nowrap"
                                         
                                        //  backgroundColor: selectedSensorsData.frid((data) => data === item.key) ? '#CCFF6
                                       }} 
                                      >
                                      {item.label}
                                    </List.Item>
                                  )}
                                />
                                </div>
                                
                              </div>
                            )}
                        </>
                        ) : (
                            <div className="spinMenu">
                                  <Spin tip="Loading" size="large" spinning={true}></Spin>
                            </div>
                        )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="ad_container_r clearfix">
                    <div className="ad_hc_header clearfix">
                      <div className="ad_hc_header_title">
                        <span className="txt_h4txt_plant">Plant</span>
                        <br />
                        {
                          // showplant == 1 ?
                          //   (
                          <span className="txt_equip_plant">

                            {equipment.plantCode ? equipment.plantCode : ""}
                          </span>
                          // )
                          // :
                          // <span className="txt_equip_plant">
                          //   {""}
                          // </span>
                        }

                      </div>
                      <div className="ad_hc_header_equip">
                        <span className="txt_h4txt_plant"> Equipment</span>
                        <br />
                        {
                           <span className="txt_equip_acc">
                           {
                               reduxSensorv2 && reduxSensorv2
                              .filter((item) => item.value === groupdata) 
                              .map((item) => item.label) 
                           }
                         </span>

                        }
                         
                      </div>
                      <div className="ad_hc_header_equip ac_sensors">
                        <span className="txt_h4txt_plant"></span>
                        <br />
                        <span className="txt_equip_acc">
                          {" "}
                          {/* {equipmentDetail ? equipmentDetail[0].totalSensor : ""} */}
                        </span>
                      </div>
                      <span>
                        <img src={EquipIco} className="ad_hc_equip_img" />
                      </span>
                    </div>
                  </div>
                  <div className="ad_calendar_r clearfix">
                    <form>
                      <div className="cate_date_opt_txt"> Range:
                        <Select className="box-select_day textcolor"
                          defaultValue=" 1 Day"
                          style={{ color: "#ffffff" }}
                          options={[
                            {
                              value: '1',
                              label: ' 1 Day',
                            },
                            {
                              value: '7',
                              label: ' 7 Day',
                            },
                            {
                              value: '14',
                              label: ' 14 Day'
                            },
                            {
                              value: '30',
                              label: ' 30 Day'
                            },
                            {
                              value: '60',
                              label: ' 60 Day'
                            }
                          ]}
                          onChange={handleChange}
                        />

                        {/* <div className="cate_date_opt_txt"> */}
                        Select Date :{" "}
                        <span className="co_wh">
                          <div className="graph_equip_search">
                            <DatePicker
                              className="graph_equip_date"
                              format={dateFormat}
                              onChange={handleDateChange}
                              onClick={() => setOpen2(!open2)}
                              defaultValue={moment().subtract(1, "day")}
                              value={
                                date
                                  ? moment(date, dateFormat)
                                  : moment("", dateFormat)
                              }
                              open={open2}
                            />
                          </div>
                          <span>
                            <i
                              className="icon-calendar1 calend_ico"
                              onClick={() => setOpen2(!open2)}
                            />
                          </span>

                        </span>
                        <Tooltip title="search">
                          <Button style={{
                            margin: "20px",
                            width: "30px",
                            height: "30px",
                            borderRadius: "9px"
                          }}
                            icon={<ReloadOutlined />}
                            onClick={handleDateChange1}
                          />
                        </Tooltip>
                        {/* </div> */}
                      </div>
                    </form>
                  </div>
                  {/* <div className="ad_loadtype clearfix">
                    <div className="ad_loadtype_head clearfix">
                      <span>LOAD TYPE</span>
                    </div>
                    {loadType ? (
                      loadType.map((item, index) => (
                        <div className="ad_loadtype_box clearfix">
                          <span className="ad_loadtype_txt txt_l txt_h5lt_num">
                            {item.loadtype}
                          </span>
                          <span className="ad_loadtype_txt txt_h5lt_result">
                            {item.weight}
                            <span className="txt_h5lt_percent">%</span>
                          </span>
                          <span className="ad_loadtype_txt txt_h5lt_mw">
                            {item.minValue} - {item.maxValue} MW
                          </span>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div> */}
                  <div className="scrollgraph custom-scrollbar">
                    <div className="ad_sens_chart_sum_anoly clearfix">
                      <span className="ad_hc_title_le left_le"> SYSTEM ANOMALY SCORE </span>
                      <div className="pla_s_charts clearfix">

                        {anomalyGraphSum?.length > 0 ? (
                          <AnomalyChartGraphSum
                            anomalyData={anomalyGraphSum.sort((a, b) => a.date_time - b.date_time)}
                            date={datesensor}
                          />

                        ) : (
                          <h1>Please select equipment</h1>
                        )}




                      </div>
                    </div>
                    <div className="ad_sens_chart_sum_anoly clearfix">
                      <span className="ad_hc_title_le left_le">  </span>
                      <div className="pla_s_charts_tank clearfix">

                        {Graphtang?.length > 0 ? (
                          <BarChart
                            data={Graphtang}
                          //  anomalyData={anomalyGraphSum.sort((a, b) => a.date_time - b.date_time)}
                          //  date={datesensor}
                          />

                        ) : (
                          <h1>Please select equipment</h1>
                        )}



                      </div>
                    </div>
                    <div className="ad_sens_chart_sum clearfix">
                      {/* Chart Summary */}
                      {selectedSensorsData?.length > 0 && sensor?.length > 0 ? (
                        selectedSensorsData.map((item, index) => {
                          return (
                            <div className="ad_sens_chart_sect clearfix" key={item.equid}>
                              <div className="data_content_title clearfix">
                                <div className="ad_sens_chart_title">
                                  <span className="txt_h3txt_chart_title">
                                    {getPlantCode(item, sensor)}
                                  </span>
                                </div>
                                <div className="ad_sens_chart_num">
                                  {/* <span className="cate_detect_opt_txt">
                                Sensors Detection1
                              </span> */}
                                  <span className="co_warn_sta">
                                    {sensor != null &&
                                      sensor != undefined &&
                                      sensor[item] ? (
                                      <>{sensor[item].score}</>
                                    ) : (
                                      <></>
                                    )}
                                  </span>
                                </div>
                              </div>
                              {/* {
                        JSON.stringify(anomaly.filter(e => (e.paramId === item.data["paraM_ID"])).map((dataG) =>{
                              return dataG.dt
                        }))
                      } */}

                              {

                                <div className="pla_s_charts clearfix">
                                  {sensornew && sensornew.length > 0 ? (
                                    anomalyGraph && Array.isArray(anomalyGraph) ? (
                                      <AnomalyChartGraph
                                        anomalyData={anomalyGraph.filter(e => (e.paramCode === item)).sort((a, b) => a.date_time - b.date_time)}

                                      />
                                    ) : (
                                      
                                      <Spin tip="Loading..." size="large" spinning={isLoading}></Spin>
                                    )
                                  ) : (
                                  
                                    <Spin tip="Loading..." size="large" spinning={isLoading}></Spin>
                                  )}
                                  {/* <Spin tip="loading" size="large" spinning={isLoadingGraph}></Spin> */}
                                </div>
                              }
                            </div>
                          );
                        })
                      ) : (
                        <> </>
                      )}
                      <Spin tip="Loading..." size="large" spinning={isLoading}></Spin>

                      {/* Chart Summary */}
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </section>
          {/* Data */}
          {/* BG */}
          <div
            className="ml_sect_bg ml_bg_fixed"
            style={{ backgroundImage: `url(${BgImage})` }}
          />
          {/* BG */}
          {/*Modal Case Issue */}
          <div
            className="modal fade"
            id="case_issue"
            data-keyboard="false"
            data-backdrop="false">
            {/*?php include("case_issue.php"); ?*/}
          </div>
          {/*Modal Case Issue */}
          <FooterComponent></FooterComponent>

        </div>
        <CaseIssue
          visible={visible}
          onHide={() => setVisible(false)}
          caseData={caseData} />
      </section>
    </>
  );
};
export default EquipmentNew;
