export const clearNotify = (payload) => ({
    type: "CLEAR_NOTIFY",
    payload,
});
export const infoNotify = (payload) => ({
    type: "INFO_NOTIFY",
    payload,
});
export const successNotify = (payload) => ({
    type: "SUCCESS_NOTIFY",
    payload,
});
export const warningNotify = (payload) => ({
    type: "WARNING_NOTIFY",
    payload,
});
export const errorNotify = (payload) => ({
    type: "ERROR_NOTIFY",
    payload,
});
export const messageNotify = (payload) => ({
    type: "MESSAGE_NOTIFY",
    payload,
});
export const closeNotify = (payload) => ({
    type: "CLOSE_NOTIFY",
    payload,
});
