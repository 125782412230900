import { combineReducers } from "redux";

import auth from "./auth";
import master from "./reducer.master";
import machineLearning from "./reducer.machine.learning";
import notification from "./reducer.notification";
import { notify }  from "./reducer.global";

const reducers = combineReducers({
  auth,
  master,
  machineLearning,
  notification,
  notify,
});
export default reducers;
