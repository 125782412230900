import React from "react";

function Footer() {
  return (
    <section className="footer_container clearfix">
      <div className="footer_bottom clearfix">
        <div className="footer_copyright">© 2024 - Gulf Energy Development PCL.</div>
      </div>
    </section>
  );
}

export default Footer;
