import { axiosProvider } from "../configs/axios.config";
import {
  ActionGetUserNotification,
  ActionClearUserNotification,
} from "../redux/action/action.notification";

import { BASE_URL } from "../configs/constant.key";
const Controller = "/Notification";

export const ServiceGetUserNotification = () => async (dispatch) => {
  dispatch(ActionClearUserNotification());
  try {
    const resp = await axiosProvider.post(
      `${BASE_URL}${Controller}/GetUserNotification`
    );
    dispatch(ActionGetUserNotification(resp.data));
  } catch {}
};
