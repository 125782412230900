import ReactDOM from "react-dom/client";
//import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./redux/stores";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";

import React, { Suspense } from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configs/constant.key.jsx";

import "./assets/css/style.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Suspense fallback={null}>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <Provider store={configureStore()}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </Provider>
      </MsalProvider>
    </BrowserRouter>
  </Suspense>
);

reportWebVitals();
