export const baseRoute = "";

/** msal */
export const clientId = "9be593a0-8fea-43d4-b20b-76bcfdedb9e2";
export const tenantId = "d39b3018-688f-4d0d-9fce-8c3cb1169c21";
export const msalAuth = `${clientId}.${tenantId}.login.windows.net-authority`;
export const apiScope = `api://9be593a0-8fea-43d4-b20b-76bcfdedb9e2/user_impersonation`;
//HOST
export const hostApi = `${process.env.REACT_APP_API_ENDPOINT}/api`;
export const hostWeb = `${process.env.REACT_APP_DOMAIN}/${baseRoute}`;
export const hostApiAuthen = `${process.env.REACT_APP_AUTH}/api`;

//local
export const BASE_URL = hostApi;

export const msalConfig = {
  auth: {
    clientId: `${clientId}`,
    authority: `https://login.microsoftonline.com/${tenantId}/`,
    validateAuthority: true,
    redirectUri: hostWeb,
    postLogoutRedirectUri: hostWeb,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "offline_access", apiScope],
};


export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
