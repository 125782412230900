import reducers from "../reducers";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// If you have the Redux Dev Tools extension installed on your browser, this enables it
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk))); // thunk middleware

store.subscribe(() => {
  //console.log("store change", store.getState());
});

export default function configureStore() {
  return store;
}
