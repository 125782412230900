import React, { useState, useEffect } from "react";
import { DatePicker, Table, Button, Tooltip, Spin } from "antd";
import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import moment from "moment";
import { connect } from "react-redux";
import { BgImage, IconEquipment } from "../assets/images";
import { SearchOutlined } from "@ant-design/icons";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  ServiceGetModelOutput,
  ServiceGetPiData,
} from "../services/machine.learning.service";
import { ServiceGetEquipmentByPlant } from "../services/master.service";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import "../assets/css/custom-antdesign.css";
import "antd/dist/antd.min.css";

const { RangePicker } = DatePicker;

const styleTable = {
  text: {
    color: "#fff",
  },
  hText: {
    color: "#004370",
    fontWeight: "bold",
  },
};

const Equipment = (props) => {
  const navigate = useNavigate();
  const { plantCode } = useParams();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const [modelOutput, setModelOutput] = useState();
  const [piData, setPiData] = useState();

  const [equipmentByPlant, setEquipmentByPlant] = useState();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
  const [equipId, setEquipId] = useState();
  const [seletePi, setSeletePi] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPi, setIsLoadingPi] = useState(false);

  useEffect(() => {
    if (!startDate && !endDate && !search) {
      const priorDate = moment(startDate).add(-1, "days").format(dataFormatApi);
      setStartDate(priorDate);
      setEndDate(priorDate);
    }
  }, [startDate, endDate, search]);

  useEffect(() => {
    if (plantCode) {
      setModelOutput();
      setIsLoading(true);
      (async () => {
        SetEquipment();
      })();
    }
    if (search) {
      const latestTime = searchParams.get("latestTime");
      const latestDateTime = moment(latestTime).format(dataFormatApi);
      setStartDate(latestDateTime);
      setEndDate(latestDateTime);
    }
  }, [plantCode, search]);

  useEffect(() => {
    if (piData) {
      setColumnsPiData();
      setRowsPiData();
      navigate(`/equipment/${plantCode}`);
    }
  }, [piData]);

  const getServiceGetPiData = async ({ recordId, nearestRecordId }) => {
    const body = {
      recordId: recordId,
      nearestRecordId: nearestRecordId,
    };
    setIsLoadingPi(true);
    const resPiData = await ServiceGetPiData(body);
    setIsLoadingPi(false);
    if (resPiData.length > 0) {
      setPiData(resPiData);
    } else {
      setData([]);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const SetEquipment = async () => {
    let item = [];
    const equip = await ServiceGetEquipmentByPlant(plantCode);
    for (let i = 0; i < 10; i++) {
      if (equip.length > i) {
        let decodedStringAtoB, decodedStringAtoBHover;
        if (equip[i].equipImage) {
          decodedStringAtoB = atob(equip[i].equipImage);
        }
        if (equip[i].equipImageHover) {
          decodedStringAtoBHover = atob(equip[i].equipImageHover);
        }
        const element = {
          equipId: equip[i].equipId,
          equipImage: equip[i].equipImage ? decodedStringAtoB : IconEquipment,
          equipImageHover: equip[i].equipImageHover
            ? decodedStringAtoBHover
            : IconEquipment,
          equipName: equip[i].equipName,
        };
        item.push(element);
      } else {
        setIsLoading(false);
        const element = {
          equipId: 0,
          equipImage: IconEquipment,
          equipName: "N/A",
        };
        item.push(element);
      }
    }
    setEquipmentByPlant([...item]);

    if (equip.length > 0) {
      if (search) {
        const equipId = searchParams.get("equipId");
        const recordId = searchParams.get("recordId");

        setEquipId(equipId);
        GetModelOutput(equipId);
      } else {
        setEquipId(item[0].equipId);
        GetModelOutput(item[0].equipId);
      }
    }
  };

  const GetModelOutput = async (equipId) => {
    setModelOutput();
    const latestTime = searchParams.get("latestTime");
    const latestDateTime = moment(latestTime).format(dataFormatApi);
    const body = {
      plantCode: plantCode,
      equipId: equipId,
      srecordDateTime: search
        ? latestDateTime
        : startDate === undefined
        ? moment().add(-1, "days").format(dataFormatApi)
        : startDate,
      erecordDateTime: search
        ? latestDateTime
        : endDate === undefined
        ? moment().add(-1, "days").format(dataFormatApi)
        : endDate,
    };
    const item = await ServiceGetModelOutput(body);
    if (item.length > 0) {
      const myElement = document.getElementById("chartdiv");
      myElement.style.removeProperty("color");
      myElement.innerHTML = "";
      myElement.style.removeProperty("display");
      myElement.style.removeProperty("text-align");
      myElement.style.removeProperty("flex-direction");
      myElement.style.removeProperty("justify-content");
      myElement.style.removeProperty("font-size");
      myElement.style.removeProperty("width");
      for (let i = 0; i < item.length; i++) {
        item[i].time = moment(item[i].recordDateTime).format("DD/MM HH:mm");
        item[i].dataTime_tooltip = moment(item[i].recordDateTime).format(
          "HH:mm"
        );
        item[i].date_test = moment(item[i].recordDateTime).format("YYYY-MM-DD");
      }
      setModelOutput([...item]);
      getServiceGetPiData({
        recordId: item[0].recordId,
        nearestRecordId: item[0].nearestRecordId,
      });
      setSeletePi(item[0]);
    } else {
      setData([]);
      setIsLoading(false);
    }
  };
  const setColumnsPiData = () => {
    let col = [
      {
        title: () => <p style={styleTable.text}>ITEM</p>,
        dataIndex: "item",
        key: "item",
        fixed: "left",
        width: 130,
        render: (text) => <p style={styleTable.hText}>{text}</p>,
      },
    ];
    for (let i = 0; i < piData.length; i++) {
      const element = {
        title: () => (
          <Tooltip placement="topLeft" title={piData[i].paramCode}>
            {" "}
            <p
              style={
                styleTable.text
              }>{`${piData[i].paramDescription} (${piData[i].paramUnit})`}</p>
          </Tooltip>
        ),
        dataIndex: `${piData[i].paramCode}`,
        key: `${piData[i].paramCode}`,
        render: (text) => <p>{text}</p>,
      };
      col.push(element);
    }
    setColumns([...col]);
  };
  const setRowsPiData = () => {
    if (piData.length > 0) {
      const row = [
        {
          item: "Actual",
          name: "actual",
        },
        {
          item: "Nearest",
          name: "nearest",
        },
        {
          item: "Diff",
          name: "diff",
        },
        {
          item: "%Diff",
          name: "diffPercent",
        },
        {
          item: "Weight (%)",
          name: "weight",
        },
      ];
      for (let i = 0; i < row.length; i++) {
        if (piData.length > 0) {
          for (let o = 0; o < piData.length; o++) {
            row[i][`${piData[o].paramCode}`] = piData[o][`${row[i].name}`];
          }
        }
      }
      setData([...row]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (modelOutput && modelOutput.length > 0) {
      const root = am5.Root.new("chartdiv");
      //  const legendRoot = am5.Root.new("legend-div");
      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        })
      );

      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        })
      );
      chart.set(
        "scrollbarY",
        am5.Scrollbar.new(root, {
          orientation: "vertical",
        })
      );

      var xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 15,
      });

      xRenderer.labels.template.setAll({
        rotation: 60,
        centerY: am5.p50,
        centerX: 0,
        fontSize: 10,
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "time",
          tooltip: am5.Tooltip.new(root, {}),
          renderer: xRenderer,
        })
      );

      const data = modelOutput;
      xAxis.data.setAll(data);
      let arrayDistance = [];
      for (let i = 0; i < data.length; i++) {
        arrayDistance.push(+data[i].distance.toFixed());
      }
      const largest = Math.max(...arrayDistance);
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 1,
          max: largest >= 20 ? largest : 20,
          //extraMax: 0.1,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      chart.get("colors").set("step", 2);

      var series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Distance",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "distance",
          categoryXField: "time",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name} in {time} : {valueY}",
          }),
          fill: am5.color("rgba(38, 164, 248, 1)"),
          calculateAggregates: true,
        })
      );
      // Add bullet
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets

      var circleTemplate = am5.Template.new({});
      circleTemplate.events.on("click", function (ev) {
        const data = ev.target.dataItem.dataContext;
        setIsLoading(true);
        getServiceGetPiData({
          recordId: data.recordId,
          nearestRecordId: data.nearestRecordId,
        });

        setSeletePi(data);
      });
      series1.bullets.push(function () {
        var graphics = am5.Circle.new(
          root,
          {
            fill: series1.get("fill"),
            fillOpacity: 0.3,
            radius: 5,
          },
          circleTemplate
        );
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      series1.set("heatRules", [
        {
          target: circleTemplate,
          min: 0,
          max: 1,
          dataField: "distance",
          key: "radius",
        },
      ]);

      series1.data.setAll(data);

      var series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Moving Average",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "mavgDistance",
          categoryXField: "time",
          fill: am5.color("rgba(255,0,0,1)"),
          stroke: am5.color("rgba(255,0,0,1)"),
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "Moving Average in {time} : {valueY} ",
          }),
        })
      );

      series2.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings",
      });

      series2.data.setAll(data);

      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 0,
            fill: root.interfaceColors.get("background"),
            //  fill: am5.color("rgba(255, 67, 112, 1)"),
          }),
        });
      });

      var series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Threshold Distance",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "thresholdDistance",
          categoryXField: "time",
          fill: am5.color("rgba(106, 185, 111, 1)"),
          stroke: am5.color("rgba(106, 185, 111, 1)"),
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "Threshold Distance in {time} : {valueY} ",
          }),
        })
      );

      series3.strokes.template.setAll({
        strokeWidth: 5,
        strokeDasharray: [5, 3],
        templateField: "strokeSettings",
      });

      series3.data.setAll(data);

      series3.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series3.get("stroke"),
            radius: 0,
            fill: root.interfaceColors.get("background"),
          }),
        });
      });

      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      legend.data.setAll(chart.series.values);

      series1.appear(1000);
      chart.appear(1000, 100);
      return () => {
        root.dispose();
      };
    } else {
      const myElement = document.getElementById("chartdiv");
      myElement.style.color = "rgba(0, 0, 0, 0.25)";
      myElement.innerHTML = "No Data";
      myElement.style.display = "flex";
      myElement.style.textAlign = "center";
      myElement.style.flexDirection = "column";
      myElement.style.justifyContent = "center";
      myElement.style.fontSize = "20px";
      myElement.style.width = "100%";
    }
  }, [modelOutput]);

  const onChangeDate = (event) => {
    const start = moment(event[0]).format(dataFormatApi);
    const end = moment(event[1]).format(dataFormatApi);
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className="layout_all_equip">
      <MenuComponent {...props} />
      <section className="eq_container clearfix">
        <div className="eq_content clearfix">
          <div className="eq_sentence">
            <h2 className="txt_h2txt">Stream turbine gen</h2>
            <br />
            <h1 className="txt_h1txt">Equipment Status</h1>
            <br />
            Machine learning is a branch of artificial intelligence (AI) and
            computer science which focuses on the use of data and algorithms.
          </div>
        </div>
      </section>

      {/* <!-- Select Categories --> */}
      <section className="eq_cate_sect clearfix">
        {/* <!-- Categories --> */}

        <div className="eq_12spps eq_plant_row clearfix">
          <div className="eq_plant_title txt_h5txt_w">
            <div className="eq_plant_title_cate">Select Categories</div>
          </div>
          <div className="eq_plant_group clearfix">
            {equipmentByPlant && equipmentByPlant.length > 0 ? (
              equipmentByPlant.map((obj, index) =>
                obj.equipId !== 0 ? (
                  <div
                    className="eq_plant_col clearfix"
                    key={index}
                    onClick={() => GetModelOutput(obj.equipId)}>
                    <div className="eq_plant_normal clearfix">
                      {obj.equipImage ? (
                        <>
                          <div
                            className="img_equip"
                            dangerouslySetInnerHTML={{
                              __html:
                                obj.equipId === equipId
                                  ? obj.equipImage
                                  : obj.equipImageHover,
                            }}></div>
                          <div className="eq_plant_ico">
                            <div
                              className="eq_plant_img"
                              dangerouslySetInnerHTML={{
                                __html: obj.equipImage,
                              }}></div>
                          </div>
                        </>
                      ) : (
                        <img src={IconEquipment} className="img_equip" />
                      )}
                    </div>
                    <div className="eq_plant_sub clearfix">
                      <h2 className="txt_equip_name">{obj.equipName}</h2>
                      <br />
                    </div>
                  </div>
                ) : (
                  <div className="eq_plant_col eq_na clearfix" key={index}>
                    <div className="eq_plant_normal clearfix">
                      <img src={IconEquipment} className="img_equip" />
                    </div>
                    <div className="eq_plant_sub clearfix">
                      <h2 className="txt_equip_name">N/A</h2>
                      <br />
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="eq_plant_col eq_na clearfix">
                <div className="eq_plant_normal clearfix">
                  <img src={IconEquipment} className="img_equip" />
                </div>
                <div className="eq_plant_sub clearfix">
                  <h2 className="txt_equip_name">N/A</h2>
                  <br />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <!-- Select Categories --> */}

      {/* <!-- Data --> */}
      <section className="data_container clearfix">
        <div className="data_content_title clearfix">
          <div className="eq_sentence">
            <h2 className="txt_h3txt_wh">Plant Status</h2>
            <br />
            <h1 className="txt_h1txt2_gr">{plantCode}</h1>
          </div>
        </div>
        <div className="data_content clearfix graph_equip">
          {startDate && endDate ? (
            <div className="graph_equip_search">
              <RangePicker
                className="graph_equip_date"
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={(e) => onChangeDate(e)}
              />
              <Tooltip title="search">
                <Button
                  type="dashed"
                  onClick={() => GetModelOutput(equipId)}
                  icon={<SearchOutlined />}
                />
              </Tooltip>
            </div>
          ) : (
            <></>
          )}

          <Spin tip="Loading..." size="large" spinning={isLoading}>
            <div id="chartdiv" style={{ height: "400px" }}></div>
          </Spin>
        </div>
      </section>
      {/* <!-- Data --> */}

      {/* <!-- Data --> */}
      <section className="data_container clearfix">
        <div className="data_content_title clearfix">
          <div className="eq_sentence">
            {seletePi ? (
              <>
                <h2 className="txt_h3txt">
                  {`Data Point at ${moment(seletePi.recordDateTime).format(
                    "MMMM DD, YYYY HH:mm"
                  )}`}
                </h2>
                <br />
                <p>
                  Distance:{" "}
                  <strong style={{ color: "rgba(228, 123, 75, 1)" }}>
                    {" "}
                    {` ${seletePi.distance.toFixed(2)}`}
                  </strong>{" "}
                  | Moving Average:
                  <strong style={{ color: "rgba(228, 123, 75, 1)" }}>
                    {" "}
                    {` ${seletePi.mavgDistance.toFixed(2)}`}
                  </strong>{" "}
                  | Threshold Distance:
                  <strong style={{ color: "rgba(228, 123, 75, 1)" }}>
                    {" "}
                    {` ${seletePi.thresholdDistance.toFixed(2)}`}
                  </strong>
                </p>
              </>
            ) : (
              <></>
            )}
            <br />
            <h1 className="txt_h1txt2">DETAILS</h1>
          </div>
        </div>
        <div className="data_content clearfix">
          <Table
            loading={isLoadingPi}
            scroll={{ x: 3000 }}
            columns={columns}
            dataSource={data}
          />
        </div>
      </section>
      {/* <!-- Data --> */}

      {/* <!-- BG --> */}
      <div
        className="eq_sect_bg eq_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}></div>
      {/* <!-- BG --> */}
      <FooterComponent />
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Equipment);
