const initialState = {
    isShow: false,
    status: 0,
    event: "error",
    title: "Message",
    message: "",
    duration: 5,
  };
  
  export const notify = (state = initialState, action = {}) => {
    switch (action.type) {
      case "CLEAR_NOTIFY":
        return Object.assign({}, state, {
          isShow: false,
          status: 0,
          message: "",
        });
      case "MESSAGE_NOTIFY":
        return Object.assign({}, state, {
          isShow: true,
          event: action.payload.status === 200 ? "success" : "error",
          status: action.payload.status,
          message: action.payload.message,
        });
      case "CLOSE_NOTIFY":
        return Object.assign({}, state, {
          isShow: false,
          status: 0,
          message: "",
        });
      default:
        return state;
    }
  };
  