import React, { useState } from "react";
import { connect } from "react-redux";
import {
  LineBgPic,
  SigninPic,
  MlDigitalWebm,
  MlDigital,
} from "../assets/images";

import "../assets/css/signin.css";
import "../assets/css/indexNew.css";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../configs/constant.key";
const Signin = (props) => {
  const [messgeErr, setMessageErr] = useState(props.error);
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      setMessageErr("Sign-in failed. Please try again.");
      console.error(e);
    });
  };

  return (
    <div>
      <section className="sign_panel clearfix">
        <div className="sign_col clearfix">
          <div className="sign_logo">
            <Link to="/">
              <i className="icong-gulf_logo_wh signin_logo"></i>
            </Link>
            <br />
            <h1 className="txt_h3txt_wh">Gulf Energy Development PCL.</h1>
          </div>
          <div className="sign_content">
            <h2 className="txt_h2txt_sign">Monitoring Process</h2>
            <br />
            <h1 className="txt_h1txt2_sign">Machine Learning</h1>
            <br />
            <br />
            <span className="txt_h4txt_sign">Azure Authentication</span>
            <br />
            <span className="txt_h5txt_body">
              Identification and authentication Identification is the ability to
              identify uniquely a user of a system or an application that is
              running in the system. Authentication is the ability to prove that
              a user or application is genuinely who that person or what that
              application claims to be.
            </span>
            <div className="sign_btn" onClick={() => handleLogin()}>
              Sign in
            </div>
            <div className="txt_err mt-2">{messgeErr}</div>
          </div>
        </div>
      </section>

      {/* <!-- BG --> */}
      <div
        className="sign_linebg_fixed sign_line_bg"
        style={{
          backgroundImage: `url(${LineBgPic})`,
          filter: "invert(0.3)",
        }}></div>
      <div
        className="sign_bg_fixed sign_sect_bg"
        style={{ backgroundImage: `url(${SigninPic})` }}></div>
      {/* <!-- BG --> */}

      {/* <!-- Video BG --> */}
      <video className="ov_vidbg" loop autoPlay muted>
        <source src={MlDigital} type="video/mp4" />
        <source src={MlDigitalWebm} type="video/webm" />
      </video>
      {/* <!-- Video BG --> */}

      <section className="sign_footer_fixed footer_container clearfix">
        <div className="footer_bottom clearfix">
          <div className="footer_copyright">
            © 2024 - Gulf Energy Development PCL.
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
