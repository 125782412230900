import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PlantStatus({ plantStatusData, date, clickFunction }) {
  const plantStatusContainerRef = useRef(null);
  let root = null;
  const handleClick = (target) => {
    clickFunction(target);
  };
  useEffect(() => {
    if (plantStatusData) {
      if (plantStatusData.length > 0) {
        const newPlantStatusData = plantStatusData.map((item) => {
          const date = new Date(item.recordDatetime);
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formatTime = `${month}/${day} ${hours}:${minutes}:${seconds}`;

          return {
            ...item,
            formatTime: formatTime,
          };
        });

        root = am5.Root.new(plantStatusContainerRef.current);
        //  const legendRoot = am5.Root.new("legend-div");
        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            layout: root.verticalLayout,
          })
        );

        // chart.set(
        //   "scrollbarX",
        //   am5.Scrollbar.new(root, {
        //     orientation: "horizontal",
        //   })
        // );
        // chart.set(
        //   "scrollbarY",
        //   am5.Scrollbar.new(root, {
        //     orientation: "vertical",
        //   })
        // );

        var xRenderer = am5xy.AxisRendererX.new(root, {
          minGridDistance: 15,
        });

        xRenderer.labels.template.setAll({
          rotation: 60,
          centerY: am5.p50,
          centerX: 0,
          fontSize: 10,
        });

        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            categoryField: "formatTime",
            tooltip: am5.Tooltip.new(root, {}),
            renderer: xRenderer,
          })
        );

        const data = newPlantStatusData;
        xAxis.data.setAll(data);
        let arrayDistance = [];
        for (let i = 0; i < data.length; i++) {
          arrayDistance.push(+data[i].knnDistance.toFixed());
        }
        const largest = Math.max(...arrayDistance);
        var yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            min: 0,
            max: 50,
            strictMinMax: true,
            //extraMax: 0.1,
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        );

        chart.get("colors").set("step", 2);

        var series1 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Distance",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "knnDistanceforGraph",
            categoryXField: "formatTime",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "{name} in {formatTime} : {knnDistance}",
            }),
            fill: am5.color("rgba(38, 164, 248, 1)"),
            calculateAggregates: true,
          })
        );
        // Add bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets

        var circleTemplate = am5.Template.new({});
        circleTemplate.events.on("click", function (ev) {
          const data = ev.target.dataItem.dataContext;

          // getServiceGetPiData({
          //   recordId: data.recordId,
          //   nearestRecordId: data.nearestRecordId,
          // });

          // setSeletePi(data);
        });
        series1.bullets.push(function () {
          var graphics = am5.Circle.new(
            root,
            {
              fill: series1.get("fill"),
              fillOpacity: 0.3,
              radius: 5,
              toggleKey: "active",
            },
            circleTemplate
          );
          graphics.on("active", function (active, target) {
            handleClick(target);
          });
          return am5.Bullet.new(root, {
            sprite: graphics,
          });
        });
        series1.set("heatRules", [
          {
            target: circleTemplate,
            min: 0,
            max: 1,
            dataField: "knnDistance",
            key: "radius",
          },
        ]);

        series1.data.setAll(data);

        var series2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Moving Average",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "mavgKnnDistanceforGraph",
            categoryXField: "formatTime",
            fill: am5.color("rgba(255,0,0,1)"),
            stroke: am5.color("rgba(255,0,0,1)"),
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "Moving Average in {formatTime} : {mavgKnnDistance} ",
            }),
          })
        );

        series2.strokes.template.setAll({
          strokeWidth: 3,
          templateField: "strokeSettings",
        });

        series2.data.setAll(data);

        series2.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series2.get("stroke"),
              radius: 0,
              fill: root.interfaceColors.get("background"),
              //  fill: am5.color("rgba(255, 67, 112, 1)"),
            }),
          });
        });

        var series3 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Threshold Distance",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "knnThreshold1",
            categoryXField: "formatTime",
            fill: am5.color("rgba(106, 185, 111, 1)"),
            stroke: am5.color("rgba(106, 185, 111, 1)"),
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "Threshold Distance in {formatTime} : {valueY} ",
            }),
          })
        );

        series3.strokes.template.setAll({
          strokeWidth: 5,
          strokeDasharray: [5, 3],
          templateField: "strokeSettings",
        });

        series3.data.setAll(data);

        series3.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series3.get("stroke"),
              radius: 0,
              fill: root.interfaceColors.get("background"),
            }),
          });
        });

        chart.set("cursor", am5xy.XYCursor.new(root, {}));

        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
          })
        );
        legend.data.setAll(chart.series.values);

        series1.appear(1000);
        chart.appear(1000, 100);
        return () => {
          root.dispose();
        };
        //   } else {
        //     const myElement = document.getElementById(plantStatusContainerRef);
        //     myElement.style.color = "rgba(0, 0, 0, 0.25)";
        //     myElement.innerHTML = "No Data";
        //     myElement.style.display = "flex";
        //     myElement.style.textAlign = "center";
        //     myElement.style.flexDirection = "column";
        //     myElement.style.justifyContent = "center";
        //     myElement.style.fontSize = "20px";
        //     myElement.style.width = "100%";
        //   }
      }
      // else {
      //   const myElement = document.getElementById(plantStatusContainerRef);
      //   myElement.style.color = "rgba(0, 0, 0, 0.25)";
      //   myElement.innerHTML = "No Data";
      //   myElement.style.display = "flex";
      //   myElement.style.textAlign = "center";
      //   myElement.style.flexDirection = "column";
      //   myElement.style.justifyContent = "center";
      //   myElement.style.fontSize = "20px";
      //   myElement.style.width = "100%";
      // }
    }
  }, [plantStatusData, date]);
  return (
    <div
      ref={plantStatusContainerRef}
      style={{ width: "100%", height: "500px" }}
      id={plantStatusContainerRef}></div>
  );
}
export default PlantStatus;
