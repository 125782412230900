export const ActionGetUserNotification= (payload) => ({
  type: "GET_USER_NOTIFICATION",
  payload,
});

export const ActionClearUserNotification= (payload) => ({
  type: "CLEAR_USER_NOTIFICATION",
  payload,
});

// /api/Notification/GetUserNotification