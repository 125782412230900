import { object } from "@amcharts/amcharts5";

const initialState = {
  modelOutput: null,
  piData: null,
  plantOverview: null,
  allAbnormalItems: null,
  dataIssued: null,
  equipmentOverview: null,
  healthScore: null,
  anomaly: null,
  plantStatus: null,
  sensor: null,
  sensorsum: null,
  loadType: null,
  ranking:null,
  detail: null,
  sensorImg: null,
  logUpdateIssuelast: null,
  IssueSummary: null,
  userEditingRoles: null,
  manualFiledownloading: false,
  runtime:null,
  alert:null,
  mlissue:null,
  SensorV2:null,
  SensorGraph:null,
  daterank:null,
  date: null
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_MODEL_OUTPUT":
      return Object.assign({}, state, {
        modelOutput: action.payload.data,
      });
    case "CLEAR_MODEL_OUTPUT":
      return Object.assign({}, state, {
        modelOutput: null,
      });
    case "GET_PI_DATA":
      return Object.assign({}, state, {
        piData: action.payload.data,
      });
    case "CLEAR_PI_DATA":
      return Object.assign({}, state, {
        piData: null,
      });
    case "GET_PLANT_OVER_VIEW":
      return Object.assign({}, state, {
        plantOverview: action.payload.data,
      });
    case "CLEAR_PLANT_OVER_VIEW":
      return Object.assign({}, state, {
        plantOverview: null,
      });
    case "GET_INITIAL_DATA_ISSUED":
      return Object.assign({}, state, {
        dataIssued: action.payload.data,
      });
    case "CLEAR_INITIAL_DATA_ISSUED":
      return Object.assign({}, state, {
        dataIssued: null,
      });
    case "GET_ALL_ABNORMAL_ITEMS":
      return Object.assign({}, state, {
        allAbnormalItems: action.payload.data,
      });
    case "CLEAR_ALL_ABNORMAL_ITEMS":
      return Object.assign({}, state, {
        allAbnormalItems: null,
      });
    case "GET_EQUIPMENT_OVERVIEW_BY_PLANT_ENHANCE":
      return Object.assign({}, state, {
        equipmentOverview: action.payload.data,
      });
    case "CLEAR_EQUIPMENT_OVERVIEW_BY_PLANT_ENHANCE":
      return Object.assign({}, state, {
        equipmentOverview: null,
      });
    case "GET_HEALTH_SCORE":
      return Object.assign({}, state, {
        healthScore: action.payload.data,
      });
    case "CLEAR_HEALTH_SCORE":
      return Object.assign({}, state, {
        healthScore: null,
      });
    case "GET_ANOMALY":
      return Object.assign({}, state, {
        anomaly: action.payload.data,
      });
    case "CLEAR_ANOMALY":
      return Object.assign({}, state, {
        anomaly: null,
      });
    case "GET_PLANT_STATUS":
      return Object.assign({}, state, {
        plantStatus: action.payload.data,
      });
    case "CLEAR_PLANT_STATUS":
      return Object.assign({}, state, {
        plantStatus: null,
      });
    case "GET_SENSOR":
      // console.log('GET_SENSOR',action.payload.data)
      return Object.assign({}, state, {
        
        sensor: action.payload.data,
      });
    case "CLEAR_SENSOR":
      return Object.assign({}, state, {
        sensor: null,
      });
      case "GET_SensorV2":
        // console.log('SensorV2',action.payload.data)
        return Object.assign({}, state, {
          
          SensorV2: action.payload.data,
        });
      case "CLEAR_SensorV2":
        return Object.assign({}, state, {
          SensorV2: null,
        });
        case "GET_SensorGraph":
          //  console.log('SensorGraph',action.payload.data)
          return Object.assign({}, state, {
            
            SensorGraph: action.payload.data,
          });
        case "CLEAR_SensorGraph":
          return Object.assign({}, state, {
            SensorGraph: null,
          });
        case "GET_DATERANK":
             console.log('GET_DATERANK',action.payload);
          return Object.assign({},state, {
            daterank: action.payload,
          });
        case "CLEAR_DATERANK":
          return Object.assign({}, state ,{
            daterank: null,
          });
          case "GET_DATE":
          return Object.assign({},state, {
            date: action.payload,
          });
        case "CLEAR_DATE":
          return Object.assign({}, state ,{
            date: null,
          });
      case "GET_SENSORSUM":
        // console.log('GET_SENSORSUM',action.payload.data)
        return Object.assign({}, state, {
          sensorsum: action.payload.data,
        });
      case "CLEAR_SENSORSUM":
        return Object.assign({}, state, {
          sensorsum: null,
        });
        case "GET_RANKING":
          return Object.assign({}, state, {
            ranking: action.payload.data,
          });
        case "CLEAR_RANKING":
          return Object.assign({}, state, {
            ranking: null,
          });
          case "GET_RUNTIME":
            return Object.assign({}, state, {
              
              runtime: action.payload.data,
            });
          case "CLEAR_RUNTIME":
            return Object.assign({}, state, {
              runtime: null,
            });
            case "GET_ALERT":
           
            return Object.assign({}, state, {
              
              alert: action.payload.data,
            });
          case "CLEAR_ALERT":
            return Object.assign({}, state, {
              alert: null,
            });
            case "GET_MLIssue":
            return Object.assign({}, state, {
              
              mlissue: action.payload.data,
            });
          case "CLEAR_MLIssue":
            return Object.assign({}, state, {
              mlissue: null,
            });
  
    case "GET_LOAD_TYPE":
      return Object.assign({}, state, {
        loadType: action.payload.data,
      });
    case "CLEAR_LOAD_TYPE":
      return Object.assign({}, state, {
        loadType: null,
      });
    case "GET_DETAIL":
      return Object.assign({}, state, {
        detail: action.payload.data,
      });
    case "CLEAR_DETAIL":
      return Object.assign({}, state, {
        detail: null,
      });
    case "GET_SENSOR_IMG":
      return Object.assign({}, state, {
        sensorImg: action.payload.data,
      });
    case "CLEAR_SENSOR_IMG":
      return Object.assign({}, state, {
        sensorImg: null,
      });
    case "STORE_SENSOR_IMG":
      return Object.assign({}, state, {
        sensorImg: action.payload,
      });
    case "CLEAR_LOG_UPDATE_ISSUE_Last":
      return Object.assign({}, state, {
        logUpdateIssuelast: null,
      });
    case "Get_LOG_UPDATE_ISSUE_Last":
      return Object.assign({}, state, {
        logUpdateIssuelast: action.payload.data,
      });

    case "CLEAR_EnhGetIssueSummary":
      return Object.assign({}, state, {
        IssueSummary: null,
      });
    case "GET_EnhGetIssueSummary":
      return Object.assign({}, state, {
        IssueSummary: action.payload.data,
      });

    case "CLEAR_USER_EDITING_ROLE":
      return Object.assign({}, state, {
        userEditingRoles: null,
      });
    case "GET_USER_EDITING_ROLE":
      return Object.assign({}, state, {
        userEditingRoles: action.payload.data,
      });

    case "DOWNLOAD_MANUAL_FILE":
      return Object.assign({}, state, {
        manualFiledownloading: true,
      });
    case "DOWNLOAD_MANUAL_FILE_SUCCESS":
      return Object.assign({}, state, {
        manualFiledownloading: false,
      });

    default:
      return state;
  }
};
