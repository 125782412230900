import React, { useLayoutEffect,useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ServiceEnhGetSensorByparam } from "../services/machine.learning.service";
import { showGraph }  from '../pages/EquipmentPageNew';



const BarChart = ({ data }) => {

  const dateFormat = "YYYY/MM/DD";
  const dataFormatApi = "YYYY-MM-DDTHH:mm:ss";
  let today = new Date();
  today.setDate(today.getDate() - 1)
  
  const [date, setDate] = useState(
    moment().subtract(1, "day").format(dataFormatApi)
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // setDate(date.format(dataFormatApi));
  const reduxDateRank = useSelector((state) => state.machineLearning.daterank)
  const reduxDate = useSelector((state) => state.machineLearning.date)

  useLayoutEffect(() => {
      // console.log("dataGTGG",data)
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("visible", false);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 15,
      minorGridEnabled: true,
    });

    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p50,
      paddingRight: 5,
      maxHeight: 60,
      fontSize: 12,
      wrap: true,
      maxWidth: 120,
    });

    yRenderer.grid.template.setAll({
      location: 1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "paramDescription",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.data.setAll(data);

  yAxis.get("renderer").labels.template.setup = target => {
    target.setAll({
      cursorOverStyle: "pointer",
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 0
      })
    });
  };



  yAxis.get("renderer").labels.template.events.on("click", e => {
    // showGraph([e.target.dataItem.dataContext.key],[e.target.dataItem.dataContext.key],e.target.dataItem.dataContext)
    (async () => {
      try {
        // setIsLoading(true);
        await dispatch(ServiceEnhGetSensorByparam(
          JSON.stringify({
            plantCode: e.target.dataItem.dataContext.plantCode,
            equiP_ID: e.target.dataItem.dataContext.equid,
            date: reduxDate ?? date,
            Day: reduxDateRank ?? 1,
            paramId: e.target.dataItem.dataContext.paramId
          })
        ));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setIsLoading(false);
      }
    })();
  });



    let xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.1,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: xRenderer,
        min: 0,
        max: 100,
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "relativedeviationproportion",
        categoryYField: "paramDescription",
        clustered: true,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX}",
        }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
      width: am5.percent(70)  // Increase the width of the bars
    });

    // series.columns.template.events.on("click", function(ev) {
    //   let dataItem = ev.target.dataItem;
    //   if(dataItem) {
    //     let categoryY = dataItem.get("categoryY")

    //     handleColumnClick(categoryY);
    //   }
    // });

    // function handleColumnClick(categoryY) {
    //   console.log(`Clicked on: ${categoryY}`);
    // }

    series.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(10);
    });

    var scrollbarY = chart.set("scrollbarY", am5xy.XYChartScrollbar.new(root, {
      orientation: "vertical",
      height: 30,
      pinchZoomY: true,
    }));

    var sbCategoryAxisY = scrollbarY.chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "paramDescription",
      renderer: am5xy.AxisRendererY.new(root, {
        minorGridEnabled: false,
        minGridDistance: 30,
      }),
    }));

    var sbValueAxisY = scrollbarY.chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var sbSeriesY = scrollbarY.chart.series.push(am5xy.ColumnSeries.new(root, {
      valueXField: "relativedeviationproportion",
      categoryYField: "paramDescription",
      yAxis: sbCategoryAxisY,
      xAxis: sbValueAxisY,
    }));

    chart.events.on("datavalidated", function() {
      chart.yAxes.getIndex(0).zoomToIndexes(0, data.length);
    });

    let totalRange = xAxis.getPrivate("max") - xAxis.getPrivate("min");
    let zoomRange = totalRange * 0.2;
    xAxis.zoomToValues(xAxis.getPrivate("min"), xAxis.getPrivate("min") + zoomRange);

    // const sortedData = []
    // if (Array.isArray(data)) {
    
    if (!Array.isArray(data)) {
      console.error('Expected data to be an array, but got:', data);
      return null;
  }
       const sortedData = [...data].sort((a, b) => a.relativedeviationproportion - b.relativedeviationproportion);
      //  console.log("sorte",sortedData);
  
    //   if (Array.isArray(data)) {
    //      sortedData = [...data].sort((a, b) => a.relativedeviationproportion - b.relativedeviationproportion);
    //     console.log(sortedData);
    // } else {
    //     console.error('Expected data to be an array, but got:', data);
    // }

    yAxis.data.setAll(sortedData);
    series.data.setAll(sortedData);
    sbSeriesY.data.setAll(sortedData);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>;  // Increase the height of the chart
};

export default BarChart;
