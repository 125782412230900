import React, { useState, useRef, useEffect } from "react";

import MenuComponent from "../components/menu";
import FooterComponent from "../components/footer";
import { connect } from "react-redux";
import { MainPic, PlantBl, IconPlantWh, BgImage } from "../assets/images";
import {
  ServiceGetDefaultPlantByUserId,
  ServiceGetEquipmentByPlant,
} from "../services/master.service";
import moment from "moment";
import { Skeleton } from "antd";
import { ServiceGetPlantOverview } from "../services/machine.learning.service";
import { Link, useNavigate } from "react-router-dom";
import "antd/dist/antd.min.css";

const Home = (props) => {
  const [plantOverview, setPlantOverview] = useState();
  const [abnormalList, setAbnormalList] = useState();
  const [isLoading, setIsLoading] = useState();

  // useEffect(() => {
  //   if (!props.machineLearning.plantOverview) {
  //     (async () => {
  //       setIsLoading(true);
  //       await props.ServiceGetPlantOverview();
  //     })();
  //   } else {
  //     setPlantOverview(props.machineLearning.plantOverview.dashboardItems);
  //     setAbnormalList(props.machineLearning.plantOverview.abnormalItems);
  //   }
  // }, [props.machineLearning.plantOverview]);

  return (
    <div className="layout_all_home">
      <MenuComponent {...props} />
      <section className="ml_container clearfix">
        <div className="ml_content clearfix">
          <div className="ml_sentence_l">
            <h2 className="txt_h2txt">monitoring process</h2>
            <br />
            <h1 className="txt_h1txt">Machine Learning</h1>
            <br />
            Machine learning is a branch of artificial intelligence (AI) and
            computer science which focuses on the use of data and algorithms.
          </div>

          <div className="ml_image_r" data-parallax='{"x": 180}'>
            <img src={MainPic} className="img_main" />
          </div>
        </div>
      </section>
      {/* <!-- IPPS --> */}
      <section className="ml_plant_sect clearfix">
        <div className="ml_ipps ml_plant_row clearfix">
          <div className="ml_plant_title txt_h4txt">IPPS</div>
          <div className="ml_plant_group clearfix">
            {plantOverview ? (
              plantOverview
                .filter((e) => e.plantGroup === "IPPs")
                .map((obj, index) => (
                  <div className="ml_plant_col clearfix" key={obj.plantCode}>
                    <Link
                      to={{
                        pathname: `/equipment/${obj.plantCode}`,
                      }}>
                      {obj.isAbnormal ? (
                        <div className="ml_plant_abnormal clearfix">
                          <div className="ml_plant_count">{obj.icount}</div>
                          <img src={IconPlantWh} className="img_thumbs" />
                          <div className="plant_abnor_overlay">
                            <div className="normal_img">
                              <i className="icon-exclamation-outline"></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="ml_plant_normal clearfix">
                          <img src={PlantBl} className="img_thumbs" />
                          <div className="plant_nor_overlay">
                            <div className="normal_img">
                              <i className="icon-checkmark-outline"></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                    <div className="ml_plant_sub clearfix">
                      <span className="txt_plant_name">{obj.plantCode}</span>
                      <br />
                      {obj.isAbnormal ? (
                        <span className="txt_plant_status abnor_status">
                          Abnormal
                        </span>
                      ) : (
                        <span className="txt_plant_status nor_status">
                          Normal
                        </span>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="ml_plant_group clearfix">
                  <div className="ml_plant_col clearfix">
                    <div className="ml_plant_sub clearfix">
                      <Skeleton.Input active size="small" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {/* <!-- IPPS --> */}

      <section className="ml_plant_sect clearfix">
        {/* <!-- 12SPPs --> */}

        <div className="ml_12spps ml_plant_row clearfix">
          <div className="ml_plant_title txt_h4txt">12SPPs</div>
          <div className="ml_plant_group clearfix">
            {plantOverview ? (
              plantOverview
                .filter((e) => e.plantGroup === "12SPPs")
                .map((obj, index) => (
                  <div className="ml_plant_col clearfix" key={obj.plantCode}>
                    <Link
                      to={{
                        pathname: `/equipment/${obj.plantCode}`,
                      }}>
                      {obj.isAbnormal ? (
                        <div className="ml_plant_abnormal clearfix">
                          <div className="ml_plant_count">{obj.icount}</div>
                          <img src={IconPlantWh} className="img_thumbs" />
                          <div className="plant_abnor_overlay">
                            <div className="normal_img">
                              <i className="icon-exclamation-outline"></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="ml_plant_normal clearfix">
                          <img src={PlantBl} className="img_thumbs" />
                          <div className="plant_nor_overlay">
                            <div className="normal_img">
                              <i className="icon-checkmark-outline"></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                    <div className="ml_plant_sub clearfix">
                      <span className="txt_plant_name">{obj.plantCode}</span>
                      <br />
                      {obj.isAbnormal ? (
                        <span className="txt_plant_status abnor_status">
                          Abnormal
                        </span>
                      ) : (
                        <span className="txt_plant_status nor_status">
                          Normal
                        </span>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="ml_plant_group clearfix">
                  <div className="ml_plant_col clearfix">
                    <div className="ml_plant_sub clearfix">
                      <Skeleton.Input active size="small" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {/* <!-- 12SPPs --> */}

      <section className="ml_plant_sect clearfix">
        {/* <!-- 7SPPs --> */}

        <div className="ml_7spps ml_plant_row clearfix">
          <div className="ml_plant_title txt_h4txt">7SPPs</div>
          <div className="ml_plant_group clearfix">
            {plantOverview ? (
              plantOverview
                .filter((e) => e.plantGroup === "7SPPs")
                .map((obj, index) => (
                  <div className="ml_plant_col clearfix" key={obj.plantCode}>
                    <Link
                      to={{
                        pathname: `/equipment/${obj.plantCode}`,
                      }}>
                      {obj.isAbnormal ? (
                        <div className="ml_plant_abnormal clearfix">
                          <div className="ml_plant_count">{obj.icount}</div>
                          <img src={IconPlantWh} className="img_thumbs" />
                          <div className="plant_abnor_overlay">
                            <div className="normal_img">
                              <i className="icon-exclamation-outline"></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="ml_plant_normal clearfix">
                          <img src={PlantBl} className="img_thumbs" />
                          <div className="plant_nor_overlay">
                            <div className="normal_img">
                              <i className="icon-checkmark-outline"></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                    <div className="ml_plant_sub clearfix">
                      <span className="txt_plant_name">{obj.plantCode}</span>
                      <br />
                      {obj.isAbnormal ? (
                        <span className="txt_plant_status abnor_status">
                          Abnormal
                        </span>
                      ) : (
                        <span className="txt_plant_status nor_status">
                          Normal
                        </span>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="ml_plant_group clearfix">
                  <div className="ml_plant_col clearfix">
                    <div className="ml_plant_sub clearfix">
                      <Skeleton.Input active size="small" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <!-- BIOMASS --> */}
        <div className="ml_biomass ml_plant_row clearfix">
          <div className="ml_plant_title txt_h4txt">BIOMASS</div>
          <div className="ml_plant_group clearfix">
            {plantOverview ? (
              plantOverview
                .filter((e) => e.plantGroup === "Biomass")
                .map((obj, index) => (
                  <div className="ml_plant_col clearfix" key={obj.plantCode}>
                    <Link
                      to={{
                        pathname: `/equipment/${obj.plantCode}`,
                      }}>
                      {obj.isAbnormal ? (
                        <div className="ml_plant_abnormal clearfix">
                          <div className="ml_plant_count">{obj.icount}</div>
                          <img src={IconPlantWh} className="img_thumbs" />
                          <div className="plant_abnor_overlay">
                            <div className="normal_img">
                              <i className="icon-exclamation-outline"></i>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="ml_plant_normal clearfix">
                          <img src={PlantBl} className="img_thumbs" />
                          <div className="plant_nor_overlay">
                            <div className="normal_img">
                              <i className="icon-checkmark-outline"></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                    <div className="ml_plant_sub clearfix">
                      <span className="txt_plant_name">{obj.plantCode}</span>
                      <br />
                      {obj.isAbnormal ? (
                        <span className="txt_plant_status abnor_status">
                          Abnormal
                        </span>
                      ) : (
                        <span className="txt_plant_status nor_status">
                          Normal
                        </span>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="ml_plant_group clearfix">
                  <div className="ml_plant_col clearfix">
                    <div className="ml_plant_sub clearfix">
                      <Skeleton.Input active size="small" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <!-- BIOMASS --> */}
      </section>
      {/* <!-- 7SPPs --> */}

      {/* <!-- Issue Summary --> */}
      <section className="issue_container clearfix">
        <div className="issue_content clearfix">
          <div className="issue_l txt_h4txt_w clearfix">Issue summary</div>
          <div className="issue_date_r clearfix">
            <i className="icon-calendar"></i>{" "}
            {moment().format("MMMM, DD, YYYY")}
          </div>
        </div>
        <div className="issue_table clearfix">
          {/* <!-- Table Title --> */}

          <div className="issue_th th_row clearfix">
            <div className="issue_th txt_th1 clearfix">
              <div className="issue_td td_time clearfix">Date</div>
              <div className="issue_td td_time clearfix">Time</div>
              <div className="issue_td td_plant clearfix">plant</div>
              <div className="issue_td td_col clearfix">Equipment</div>
              <div className="issue_td td_col clearfix">issue</div>
              <div className="issue_td td_remark clearfix">Remark</div>
            </div>
          </div>

          {/* <!-- Table Title --> */}

          {/* <!-- Table Row --> */}
          {abnormalList ? (
            abnormalList.map((obj, index) => (
              <Link
                to={{
                  pathname: `/equipment/${obj.plantCode}`,
                  search: `?latestTime=${obj.latestTime}&equipId=${obj.equipId}&recordId=${obj.recordId}`,
                }}
                className="issue_th  clearfix"
                key={index}>
                <div className="issue_th th_brow  txt_td1 clearfix">
                  <div className="issue_td td_time clearfix">
                    {moment(obj.latestTime).format("DD/MM/YYYY")}
                  </div>
                  <div className="issue_td td_time clearfix">
                    {moment(obj.latestTime).format("HH:mm")}
                  </div>
                  <div className="issue_td td_plant clearfix">
                    {obj.plantCode}
                  </div>
                  <div className="issue_td td_col clearfix">
                    {obj.equipName}
                  </div>
                  <div className="issue_td td_col sta_abnormal clearfix">
                    Abnormal
                  </div>
                  <div className="issue_td td_remark clearfix">
                    {obj.remark ? obj.remark : "-"}
                  </div>
                  <div className="issue_td td_btn clearfix">
                    <i className="btn_arr icon-arrow-right1"></i>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <></>
          )}
        </div>
      </section>
      {/* <!-- Issue Summary --> */}

      {/* <!-- BG --> */}
      <div
        className="ml_sect_bg ml_bg_fixed"
        style={{ backgroundImage: `url(${BgImage})` }}></div>
      <FooterComponent />
      {/* <!-- BG --> */}
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  // ServiceGetPlantOverview: (props) => {
  //   dispatch(ServiceGetPlantOverview(props));
  // },
  ServiceGetDefaultPlantByUserId: (props) => {
    dispatch(ServiceGetDefaultPlantByUserId(props));
  },
  ServiceGetEquipmentByPlant: (props) => {
    dispatch(ServiceGetEquipmentByPlant(props));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
