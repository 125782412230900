import axios from "axios";
import { BASE_URL } from "./constant.key";
//import { store } from "react-redux";
import configureStore from "../redux/stores";
axios.interceptors.request.use(
  (config) => {
    const store = configureStore().getState();
    //const bearerToken = store.auth.accessToken;
    const accessToken = store.auth.accessToken;

    if (accessToken != null) {
      config.headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${accessToken}`,
      };
      config.timeout = 300000; //5 Minute
      config.baseURL = BASE_URL;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export const axiosProvider = axios;
