import React from "react";
import { notification } from "antd";
import {
    InfoCircleOutlined, ExclamationCircleOutlined,
    CloseCircleOutlined, CheckCircleOutlined,
} from "@ant-design/icons";

function iconNotify(event) {
    return event === "info" ? (
        <InfoCircleOutlined style={{ color: "#108ee9" }} />
    ) : event === "warning" ? (
        <ExclamationCircleOutlined style={{ color: "#e6b800" }} />
    ) : event === "success" ? (
        <CheckCircleOutlined style={{ color: "#2eb82e" }} />
    ) : event === "error" ? (
        <CloseCircleOutlined style={{ color: "#ff0000" }} />
    ) : (
        <></>
    );
}

function NotifyMessage(props) {
    let args = {
        icon: iconNotify(props.event),
        message: props.title,
        description: props.message,
        duration: props.duration !== 0 ? 5 : 0,
        width: 100,
        style: { backgroundColor: "rgba(255, 255, 255, 0.9)", with: 100, marginBottom: 30 },
        placement: "bottomRight",
    };
    notification.destroy();
    notification.open(args)
}

export default NotifyMessage;
