import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function HealthScoreGauge({ healthScore, textColor }) {
  console.log(healthScore);
  const gaugeContainerRef = useRef(null);
  let root = null;
  const renderSwitch = (level) => {
    switch (level) {
      case 0:
        return "Normal";
      case 1:
        return "Warning";
      case 2:
        return "Alert";

      case -1:
        return "Off";
      default:
        return '';

    }
  }
  const renderSwitchColor = (level) => {
    switch (level) {
      case 0:
        return "#90e095"; // - green
      case 1:
        return "#ffcf3b"; // - yellow
      case 2:
        return "#ff5353"; // - red
      case -1:
        return "#d3d3d3"; // - gray
      default:
        return '#d3d3d3';

    }
  }
  useEffect(() => {
    root = am5.Root.new(gaugeContainerRef.current);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    );

    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: 75,
    });

    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0,
      strokeWidth: 1,
    });
    axisRenderer.labels.template.setAll({
      visible: false,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "wrap",
      maxWidth: 150,
      textAlign: "center",
    });
    let axisDataItem = xAxis.makeDataItem({});

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        fill: renderSwitchColor(healthScore.issueLevel),
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(100),
        fontSize: "2.5em",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "bold",
      })
    );

    axisDataItem.set("value", healthScore.scoreOutliner);

    let value = axisDataItem.get("value");

    // xAxis.axisRanges.each(function (axisRange) {
    //   let value = axisRange.get("value");
    //   if (value === 0 || value === 50 || value === 100) {
    //     let axisLabel = axisRange.get("label");
    //     axisLabel.set("text", "");
    //     axisLabel.set("visible", false);
    //   }
    // });

    // let range50 = xAxis.axisRanges.create();
    // range50.value = 50;
    // range50.label.text = ""; // converting to string

    label.set("text", Math.round(value).toString());
    let textLabel = chart.radarContainer.children.push(
      am5.Label.new(root, {
        fill: renderSwitchColor(healthScore.issueLevel),
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(60),
        fontSize: "0.8em",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "200",
      })
    );
    //issueLevel
    textLabel.set("text", renderSwitch(healthScore.issueLevel));

    chart.bulletsContainer.set("mask", undefined);

    let bandsData = [
      {
        title: "",
        color: renderSwitchColor(healthScore.issueLevel),
        lowScore: 0,
        highScore: healthScore.scoreOutliner - 0.5,
      },

      {
        title: "",
        color: "#CDCDCD",
        lowScore: healthScore.scoreOutliner + 0.5,
        highScore: 100,
      },
    ];

    am5.array.each(bandsData, function (data) {
      let axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

      axisRange.setAll({
        value: data.lowScore,
        endValue: data.highScore,
      });

      axisRange.get("axisFill").setAll({
        visible: true,
        fill: am5.color(data.color),
        fillOpacity: 1,
      });

      axisRange.get("label").setAll({
        text: data.title,
        inside: true,
        radius: 15,
        fontSize: "0.9em",
        fill: root.interfaceColors.get("background"),
      });
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [healthScore]);
  return (
    <div
      ref={gaugeContainerRef}
      style={{ width: "300px", height: "170px" }}></div>
  );
}
export default HealthScoreGauge;
