import { axiosProvider } from "../configs/axios.config";
import {
  ActionGetDefaultPlantByUserId,
  ActionClearDefaultPlantByUserId,
  ActionGetAllPlantByUserId,
  ActionClearAllPlantByUserId,
  ActionClearLogUpdateIssue,
  ActionGetLogUpdateIssue,
  ActionClearLogUpdateIssueLast,
  ActionGetLogUpdateIssueLast
} from "../redux/action/action.master";

import { BASE_URL } from "../configs/constant.key";
const Controller = "/Master";

export const ServiceGetDefaultPlantByUserId = () => async (dispatch) => {
  dispatch(ActionClearDefaultPlantByUserId());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/GetDefaultPlantByUserId`
    );
    dispatch(ActionGetDefaultPlantByUserId(resp.data));
  } catch {}
};

export const ServiceGetAllPlantByUserId = () => async (dispatch) => {
  dispatch(ActionClearAllPlantByUserId());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/GetAllPlantByUserId`
    );
    dispatch(ActionGetAllPlantByUserId(resp.data));
  } catch {}
};

export const ServiceGetEquipmentByPlant = async (resource) => {
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/GetEquipmentByPlant/${resource}`
    );
    return resp.data.data;
  } catch {}
};

export const ServiceGetLogUpdateIssue = (resource) => async (dispatch) => {
  dispatch(ActionClearLogUpdateIssue());
  try {
    const resp = await axiosProvider.get(
      `${BASE_URL}${Controller}/GetLogUpdateIssue/${resource}`
    );
    dispatch(ActionGetLogUpdateIssue(resp.data));
  } catch {}
};


