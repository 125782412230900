export const ActionGetDefaultPlantByUserId = (payload) => ({
  type: "GET_DEFAULF_PLANT_BY_USER_ID",
  payload,
});

export const ActionClearDefaultPlantByUserId = (payload) => ({
  type: "CLEAR_DEFAULF_PLANT_BY_USER_ID",
  payload,
});

export const ActionGetAllPlantByUserId = (payload) => ({
  type: "GET_ALL_PLANT_BY_USER_ID",
  payload,
});

export const ActionClearAllPlantByUserId = (payload) => ({
  type: "CLEAR_ALL_PLANT_BY_USER_ID",
  payload,
});

export const ActionGetEquipmentByPlant = (payload) => ({
  type: "GET_EQUIPMENT_BY_PLANT",
  payload,
});

export const ActionClearEquipmentByPlant = (payload) => ({
  type: "CLEAR_EQUIPMENT_BY_PLANT",
  payload,
});

export const ActionGetLogUpdateIssue = (payload) => ({
  type: "GET_LOG_UPDATE_ISSUE",
  payload,
});
export const ActionClearLogUpdateIssue = (payload) => ({
  type: "CLEAR_LOG_UPDATE_ISSUE",
  payload,
});
